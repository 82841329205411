import shop from '../api/shop'
import * as types from '../constants/ActionTypes'
import store from "../store";
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import cookie from 'react-cookie';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});

export function loginUser({ email, password }, history) {
    return function(dispatch) {
    axios.post(`${REACT_APP_API_URL}/api/auth/login`, { email, password })
    .then(response => {
      const user = response.data.user;
      console.log(user)
      cookie.save('token', response.data.token, { path: '/' });
      dispatch({ type: types.AUTH_USER, payload: { userId: user._id, firstName: user.firstName, lastName: user.lastName, email: user.email } });
      localStorage.setItem('userId', user._id);
      localStorage.setItem('firstName', user.firstName);
      localStorage.setItem('email', user.email);
      localStorage.setItem('lastName', user.lastName);
      localStorage.setItem('stripeId', user.stripeId);
      history.push('/');
      dispatch({ type: types.ERROR_MESSAGE, payload: null });
    })
    .catch((error) => {
      dispatch({ type: types.ERROR_MESSAGE, payload: error });
      console.log(error);
    });
    }
  }

  export function registerUser({ firstName, lastName, email, password }, history) {
      return function(dispatch) {
      axios.post(`${REACT_APP_API_URL}/api/auth/register`, { firstName, lastName, email, password })
      .then(response => {
        const user = response.data.user;
        cookie.save('token', response.data.token, { path: '/' });
        dispatch({ type: types.AUTH_USER, payload: user._id });
        localStorage.setItem('userId', user._id);
        localStorage.setItem('firstName', user.firstName);
        localStorage.setItem('email', user.email);
        localStorage.setItem('lastName', user.lastName);
        localStorage.setItem('stripeId', user.stripeId);
        history.push('/');
        dispatch({ type: types.ERROR_MESSAGE, payload: null });
      })
      .catch((error) => {
        dispatch({ type: types.ERROR_MESSAGE, payload: error });
        console.log(error);
      });
      }
    }

    export function forgotPassword({ email }, history) {
        return function(dispatch) {
        axios.post(`${REACT_APP_API_URL}/api/forgotPassword`, { email })
        .then(response => {
          dispatch({ type: types.ERROR_MESSAGE, payload: null });
        })
        .catch((error) => {
          dispatch({ type: types.ERROR_MESSAGE, payload: error });
          console.log(error);
        });
        }
      }

  export function logoutUser() {
    return function (dispatch) {
      dispatch({ type: types.UNAUTH_USER });
      localStorage.clear();

      cookie.remove('token', { path: '/' });
    }
  }

  export function createCollection(
           {
             collectionId,
             collectionName,
             collectionType,
             value,
             noOfPeople,
             description,
             userId,
             usersFunded,
             firstName,
             lastName,
             stripeId,
             cartId,
             suggestedAmount,
             mainEmail,
           },
           history
         ) {
           return function(dispatch) {
             axios
               .post(`${REACT_APP_API_URL}/api/auth/collection`, {
                 collectionId,
                 collectionName,
                 collectionType,
                 value,
                 noOfPeople,
                 description,
                 userId,
                 usersFunded,
                 firstName,
                 lastName,
                 stripeId,
                 cartId,
                 suggestedAmount,
                 mainEmail
               })
               .then((response) => {
                 return response;
               })
               .catch((error) => {
                 console.log(error);
               });
           };
         }

    export function editCollection(
             {
               collectionId,
               collectionName,
               collectionType,
               value,
               noOfPeople,
               description,
             },
             history
           ) {
             return function(dispatch) {
               console.log(
                 collectionId,
                 collectionName,
                 collectionType,
                 value,
                 noOfPeople,
                 description
               );
               axios
                 .put(
                   `${REACT_APP_API_URL}/api/auth/collection/edit/${collectionId}`,
                   {
                     collectionId,
                     collectionName,
                     collectionType,
                     value,
                     noOfPeople,
                     description
                   }
                 )
                 .then(response => {
                   history.push(`/collection/${collectionId}`);
                 })
                 .catch(error => {
                   console.log(error);
                 });
             };
           }

  export function searchProducts({ keywords }, history) {
    return function(dispatch) {
      axios.post(`https://webservices.amazon.co.uk/onca/xml?Service=AWSECommerceService&Operation=ItemSearch&SubscriptionId=AKIAI4TJJEEQKCMC3MXQ&AssociateTag=gyphto05-21&SearchIndex=Apparel&ResponseGroup=Images,ItemAttributes,Offers&Keywords=${keywords}`)
      .then(response => {
        console.log(response)
        // history.push('/');
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }

export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})

export const getAllProducts = () => dispatch => {
    dispatch(fetchProductsBegin());
    shop.getProducts(products => {
        dispatch(receiveProducts(products));
        return products;
    })
}
export const fetchSingleProduct = productId => ({
    type: types.FETCH_SINGLE_PRODUCT,
    productId
})




//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product,qty) => (dispatch) => {
    toast.success("Item Added to Cart");
        dispatch(addToCartUnsafe(product, qty))

}
export const addToCartAndRemoveWishlist = (product,qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty));
    dispatch(removeFromWishlist(product));
}
export const addToCartUnsafe = (product, qty) => ({
    type: types.ADD_TO_CART,
    product,
    qty
});
export const removeFromCart = product_id => ({
    type: types.REMOVE_FROM_CART,
    product_id
});
export const incrementQty = (product,qty) => (dispatch) => {
    dispatch(addToCartUnsafe(product, qty))

}
export const decrementQty = productId => ({
    type: types.DECREMENT_QTY,
    productId
});



//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
    toast.success("Item Added to Wishlist");
    dispatch(addToWishlistUnsafe(product))

}
export const addToWishlistUnsafe = (product) => ({
    type: types.ADD_TO_WISHLIST,
    product
});
export const removeFromWishlist = product_id => ({
    type: types.REMOVE_FROM_WISHLIST,
    product_id
});


//Compare Products
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe= (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id
});


// Filters
export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol
});

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { userId } = this.props;
    axios
      .get(`${REACT_APP_API_URL}/api/auth/user/${userId}`, {
        headers: { Authorization: cookie.load('token') },
      })
      .then(response => {
        console.log(response);
        this.setState({ user: response.data, isLoading: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  createAccount() {
    const { userId } = this.props;
    axios
      .post(`${REACT_APP_API_URL}/api/auth/stripe/create`, {
        userId,
      })
      .then(response => {
        const stripeOnboardingLink = response.data.accountLinks.url;
        window.location = stripeOnboardingLink;
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log(error);
      });
  }

  render() {
    const { firstName, lastName, email } = this.props;
    const { user } = this.state;
    console.log(user);
    return (
      <div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#9B7DD2',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff' }}>{'My Account'}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#B395F7',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff' }}>{'Personal Details'}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="section-b-space"
          style={{
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="dashboard">
                    <div className="box-account box-info">
                      <div className="row">
                        <div className="col-sm-6">
                          <div
                            className="box"
                            style={{
                              paddingBottom: 20,
                            }}
                          >
                            <div
                              className="box-title"
                              style={{
                                backgroundColor: '#D3C3F7',
                                borderRadius: 5,
                                paddingLeft: 20,
                                paddingRight: 20,
                              }}
                            >
                              <h3>Contact Information</h3>
                            </div>
                            <div
                              className="box-content"
                              style={{
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 10,
                              }}
                            >
                              <h6>{`${firstName} ${lastName}`}</h6>
                              <h6>{email}</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="box"
                            style={{
                              paddingBottom: 20,
                            }}
                          >
                            <div
                              className="box-title"
                              style={{
                                backgroundColor: '#D3C3F7',
                                borderRadius: 5,
                                paddingLeft: 20,
                                paddingRight: 20,
                              }}
                            >
                              <h3>Stripe Account</h3>
                            </div>
                            <div
                              className="box-content"
                              style={{
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 10,
                              }}
                            >
                              <p>
                                {user && user[0].stripeId
                                  ? 'You have connected your Stripe account and you are ready to receive pledges for gifts.'
                                  : 'In order to receive funds from pledgers, connect with Stripe below.'}
                              </p>
                              <p>
                                {user && user[0].stripeId ? (
                                  ''
                                ) : (
                                  <div style={{ width: '100%' }}>
                                    <a
                                      href="#"
                                      onClick={() =>
                                        this.setState({ loading: true }, () =>
                                          this.createAccount(),
                                        )
                                      }
                                    >
                                      <img
                                        className="center"
                                        src={`${process.env.PUBLIC_URL}/assets/images/connect-button.png`}
                                      />
                                    </a>
                                  </div>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    userId: state.authentication.userId,
    firstName: state.authentication.firstName,
    lastName: state.authentication.lastName,
    email: state.authentication.email,
  };
}

export default withRouter(connect(mapStateToProps, {})(Account));

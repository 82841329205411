import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import cookie from 'react-cookie';
import Process from '../../components/common/process';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRingsWedding,
  faBirthdayCake,
  faGlassCheers,
  faHeartCircle,
  faTruckMoving,
  faBaby,
  faHandshake,
  faGraduationCap,
  faHatSanta,
  faGift,
  faCheck,
} from '@fortawesome/pro-solid-svg-icons';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class Dashboard extends Component {
  useStyles() {
    return makeStyles(theme => ({
      card: {
        display: 'flex',
        flexDirection: 'column',
        flex: 8,
      },
      content: {
        flexDirection: 'row',
      },
    }));
  }

  constructor(props) {
    super(props);

    this.state = {
      collections: [],
      isLoading: true,
    };
  }

  componentWillMount() {
    this.getCollections();
  }

  async getCollections() {
    this.setState({ isLoading: true }, async () => {
      const { userId } = this.props;
      console.log(userId);
      console.log(cookie.load('token'));
      await axios
        .get(`${REACT_APP_API_URL}/api/auth/collection/${userId}`, {
          headers: { Authorization: cookie.load('token') },
        })
        .then(response => {
          this.setState({ collections: response.data, isLoading: false });
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    });
  }

  render() {
    const { collections, isLoading } = this.state;
    const collectionTypes = [
      {
        name: 'Birthday',
        icon: faBirthdayCake,
      },
      {
        name: 'Engagement',
        icon: faGlassCheers,
      },
      {
        name: 'Wedding',
        icon: faRingsWedding,
      },
      {
        name: 'Anniversary',
        icon: faHeartCircle,
      },
      {
        name: 'New Home',
        icon: faTruckMoving,
      },
      {
        name: 'Baby Shower',
        icon: faBaby,
      },
      {
        name: 'Graduation',
        icon: faGraduationCap,
      },
      {
        name: 'Leaving',
        icon: faHandshake,
      },
      {
        name: 'Secret Santa',
        icon: faHatSanta,
      },
      {
        name: 'Other',
        icon: faGift,
      },
    ];
    return (
      <div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#9B7DD2',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff', textTransform: null }}>
                    {'My Gift Dashboard'}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-b-space" style={{ padding: 0 }}>
          <div className="container">
            <div className="row">
              <Process step={0} topSpacing />
              <div className="col-lg-8">
                <div className="dashboard-right">
                  <div className="dashboard" style={{ padding: 0, border: 0 }}>
                    {!isLoading && (
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{ height: 'auto', paddingBottom: '20px' }}
                          >
                            {collections.length > 0 ? (
                              collections.slice(0, 3).map(function(item, i) {
                                const faIcon = collectionTypes.find(type => {
                                  return type.name === item.collectionType;
                                }).icon;

                                return (
                                  <div
                                    className="col-md-12"
                                    style={{
                                      alignItems: 'center',
                                      marginTop: 20,
                                      justifyContent: 'center',
                                      padding: 0,
                                    }}
                                  >
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/collection/${item.collectionId}`}
                                    >
                                      <Card
                                        style={{
                                          flexDirection: 'row',
                                          display: 'flex',
                                          flex: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            width: '40%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: item.completed
                                              ? '#33A634'
                                              : '#9374CF',
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: 'block',
                                              height: '70px',
                                              width: '70px',
                                              lineHeight: '70px',
                                              borderRadius: '35px',
                                              borderWidth: 3,
                                              backgroundColor: item.completed
                                                ? '#33A634'
                                                : '#9374CF',
                                              borderColor: item.completed
                                                ? '#33A634'
                                                : '#9374CF',
                                              textAlign: 'center',
                                              fontSize: '2.3em',
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={
                                                item.completed
                                                  ? faCheck
                                                  : faIcon
                                              }
                                              color={
                                                item.completed
                                                  ? '#C5F7C3'
                                                  : '#D3C3F7'
                                              }
                                            />
                                          </span>
                                        </div>

                                        <CardContent
                                          style={{
                                            width: '100%',
                                            backgroundColor: '#F7F7F7',
                                          }}
                                        >
                                          <Typography
                                            component="h6"
                                            variant="h6"
                                            style={{
                                              color: '#000',
                                              textAlign: 'left',
                                              fontSize: '1.2em',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            {item.collectionName}
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            color="textSecondary"
                                            style={{
                                              textAlign: 'left',
                                              fontSize: '1.2em',
                                            }}
                                          >
                                            {(
                                              (item.amountPledged /
                                                item.value) *
                                              100
                                            ).toFixed(0) || 0}
                                            % funded
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </Link>
                                  </div>
                                );
                              })
                            ) : (
                              <div
                                className="col-md-12"
                                style={{
                                  alignItems: 'center',
                                  marginTop: 20,
                                  justifyContent: 'center',
                                  padding: 0,
                                }}
                              >
                                <h3
                                  style={{
                                    textAlign: 'center',
                                    paddingTop: 70,
                                  }}
                                >
                                  No Collections Created
                                </h3>
                                <div
                                  className="col-md-12"
                                  style={{
                                    alignItems: 'center',
                                    marginTop: 60,
                                    marginBottom: 60,
                                    justifyContent: 'center',
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                  }}
                                >
                                  <Link to={`${process.env.PUBLIC_URL}/create`}>
                                    <Card
                                      style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        flex: 10,
                                        alignItems: 'center',
                                      }}
                                    >
                                      <CardContent
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          backgroundColor: '#3CACFD',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          paddingBottom: '16px',
                                        }}
                                      >
                                        <Typography
                                          component="h6"
                                          variant="h6"
                                          style={{
                                            color: '#fff',
                                            textAlign: 'center',
                                            fontSize: '1.2em',
                                          }}
                                        >
                                          Create Collection
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </Link>
                                </div>
                              </div>
                            )}
                            {collections.length > 0 && (
                              <div
                                className="col-md-12"
                                style={{
                                  alignItems: 'center',
                                  marginTop: collections.length > 0 ? 20 : 0,
                                  justifyContent: 'center',
                                  paddingRight: 0,
                                  paddingLeft: 0,
                                }}
                              >
                                <Link
                                  to={`${process.env.PUBLIC_URL}/all-collections`}
                                >
                                  <Card
                                    style={{
                                      flexDirection: 'row',
                                      display: 'flex',
                                      flex: 10,
                                      alignItems: 'center',
                                    }}
                                  >
                                    <CardContent
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        backgroundColor: '#3CACFD',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingBottom: '16px',
                                      }}
                                    >
                                      <Typography
                                        component="h6"
                                        variant="h6"
                                        style={{
                                          color: '#fff',
                                          textAlign: 'center',
                                          fontSize: '1.2em',
                                        }}
                                      >
                                        View All Collections
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: state.authentication.userId,
    firstName: state.authentication.firstName,
  };
}

export default withRouter(connect(mapStateToProps, {})(Dashboard));

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'
import { IntlActions } from 'react-redux-multilingual'
import './index.scss';
import { AUTH_USER } from './constants/ActionTypes';
// Import custom components
import store from './store';
import translations from './constants/translations'
import { getAllProducts } from './actions';
import cookie from 'react-cookie';
// import your fontawesome library

import Dashboard from './components/pages/dashboard';
import AllCollections from "./components/pages/all-collections";
import EditCollection from "./components/pages/edit-collection";

//Collection Pages
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";
import CollectionRightSidebar from "./components/collection/collection-right-sidebar";

// Product Pages
import LeftSideBar from "./components/products/left-sidebar";
import RightSideBar from "./components/products/right-sidebar";
import NoSideBar from "./components/products/no-sidebar";
import LeftImage from "./components/products/left-image";
import RightImage from "./components/products/right-image";
import Accordian from "./components/products/accordian";
import ColumnLeft from "./components/products/column-left";
import ColumnRight from "./components/products/column-right";
import Column from "./components/products/column";
import Vertical from "./components/products/vertical";

// Features
import Layout from './components/app'
import Cart from './components/cart'
import Compare from './components/compare/index'
import wishList from './components/wishlist'
import checkOut from './components/checkout'
import orderSuccess from './components/checkout/success-page'

// Extra Pages
import aboutUs from './components/pages/about-us'
import lookbook from './components/pages/lookbook'
import Login from './components/pages/login'
import PrivacyPolicy from './components/pages/privacy-policy'
import TermsAndConditions from './components/pages/terms-and-conditions'
import Register from './components/pages/register'
import Search from "./components/pages/search";
import Product from "./components/pages/product";
import Collection from './components/pages/collection'
import ForgetPassword from './components/pages/forget-password'
import Create from './components/pages/create'
import Account from './components/pages/account'
import Faq from './components/pages/faq'
import Connect from './components/pages/connect'
import ConnectSuccess from './components/pages/connect-success'

// Blog Pages
import RightSide from './components/blogs/right-sidebar'
import MakePayment from './components/blogs/make-payment'
import BlogPage from './components/blogs/blog-page'

import RequireAuth from './components/auth/require-auth';

var lang = localStorage.getItem('locale-lang');

if(lang==null){
	lang='en';
}

const token = cookie.load('token');

if (token) {
  store.dispatch({ type: AUTH_USER });
}

class Root extends React.Component {

    render() {
      store.dispatch(getAllProducts());
	  	store.dispatch(IntlActions.setLocale(lang))
        return (
          <Provider store={store}>
            <IntlProvider translations={translations} locale="en">
              <BrowserRouter basename={"/"}>
                <ScrollContext>
                  <Switch>
                    
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/login`}
                      component={Login}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/register`}
                      component={Register}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/forget-password`}
                      component={ForgetPassword}
                    />

                    <Layout>
                      <Route
                        path={`${process.env.PUBLIC_URL}/privacy-policy`}
                        component={PrivacyPolicy}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                        component={TermsAndConditions}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/`}
                        component={RequireAuth(Dashboard)}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/all-collections`}
                        component={RequireAuth(AllCollections)}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/edit-collection/:id`}
                        component={RequireAuth(EditCollection)}
                      />

                      {/*Routes For Features (Product Collection) */}
                      <Route
                        path={`${process.env.PUBLIC_URL}/left-sidebar/collection`}
                        component={CollectionLeftSidebar}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                        component={CollectionNoSidebar}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/right-sidebar/collection`}
                        component={CollectionRightSidebar}
                      />

                      {/*Routes For Single Product*/}
                      <Route
                        path={`${process.env.PUBLIC_URL}/left-sidebar/product/:id`}
                        component={LeftSideBar}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/right-sidebar/product/:id`}
                        component={RightSideBar}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/no-sidebar/product/:id`}
                        component={NoSideBar}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/col-left/product/:id`}
                        component={ColumnLeft}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/col-right/product/:id`}
                        component={ColumnRight}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/accordian/product/:id`}
                        component={Accordian}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/column/product/:id`}
                        component={Column}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/left-image/product/:id`}
                        component={LeftImage}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/right-image/product/:id`}
                        component={RightImage}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/vertical/product/:id`}
                        component={Vertical}
                      />

                      {/*Routes For custom Features*/}
                      <Route
                        path={`${process.env.PUBLIC_URL}/cart`}
                        component={Cart}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/wishlist`}
                        component={wishList}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/compare`}
                        component={Compare}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/checkout`}
                        component={checkOut}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/order-success`}
                        component={orderSuccess}
                      />

                      {/*Routes For Extra Pages*/}
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/about-us`}
                        component={aboutUs}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/lookbook`}
                        component={lookbook}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/search/:id`}
                        component={Search}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/product/:id/:asin`}
                        component={Product}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/create`}
                        component={Create}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/account`}
                        component={Account}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/faq`}
                        component={Faq}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/connect`}
                        component={RequireAuth(Connect)}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/pages/connect-success`}
                        component={RequireAuth(ConnectSuccess)}
                      />

                      {/*Blog Pages*/}
                      <Route
                        path={`${process.env.PUBLIC_URL}/blog/right-sidebar`}
                        component={RightSide}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/collection/:id`}
                        component={Collection}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/payment/:id`}
                        component={MakePayment}
                      />
                      <Route
                        path={`${process.env.PUBLIC_URL}/blog/blog-page`}
                        component={BlogPage}
                      />
                    </Layout>
                  </Switch>
                </ScrollContext>
              </BrowserRouter>
            </IntlProvider>
          </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));

import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
import store from '../../../store';
import NavBar from "./common/navbar";
import SideBar from "./common/sidebar";
import CartContainer from "./../../../containers/CartContainer";
import TopBar from "./common/topbar";
import {changeCurrency} from '../../../actions'
import {connect} from "react-redux";

// eslint-disable-next-line react/require-render-return
class HeaderOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (number >= 300) {
      var contentwidth = window.innerWidth;
      if (contentwidth > 578) {
        document.getElementById("sticky").classList.add("fixed");
      }
    } else {
      document.getElementById("sticky").classList.remove("fixed");
    }
  };

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
    localStorage.setItem("locale-lang", lang);
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }
  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  renderPlusLink() {
    const stripeID = localStorage.getItem("stripeId");
    console.log(stripeID);
    return (
      <Link
        to={`${process.env.PUBLIC_URL}/create`}
      >
        <i className="fa fa-plus-square" style={{ color: "white" }}></i>
      </Link>
    );
  }

  renderMobileNav() {
      return (
        <header id="sticky" className="sticky">
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option"></div>
          {/*Top Header Component*/}
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="menu-left">
                    <div className="brand-logo">
                      <Link to={`${process.env.PUBLIC_URL}/`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/logo.png`}
                          style={{ width: "120px", height: "auto" }}
                          className="img-fluid"
                          alt=""
                          resizeMode="contain"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="menu-right pull-right">
                    {/*Top Navigation Bar Component*/}
                    <NavBar />
                    <div>
                      <div className="icon-nav">
                        <ul>
                          <li className="onhover-div mobile-search">
                            <div>
                              <Link to={`${process.env.PUBLIC_URL}/`}>
                                <i
                                  className="fa fa-home"
                                  style={{ color: "white" }}
                                ></i>
                              </Link>
                            </div>
                          </li>
                          <li className="onhover-div mobile-cart">
                            <div>{this.renderPlusLink()}</div>
                          </li>
                          <li className="onhover-div mobile-setting">
                            <div>
                              <Link
                                to={`${process.env.PUBLIC_URL}/account`}
                              >
                                <i
                                  className="fa fa-user-circle"
                                  style={{ color: "white" }}
                                ></i>
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      );
  }

  renderNoMobileNav() {

      return (
        <header id="sticky" className="sticky">
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          {/*Top Header Component*/}
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="menu-left">
                    <div className="brand-logo">
                      <Link to={`${process.env.PUBLIC_URL}/`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/logo.png`}
                          style={{ width: "120px", height: "30px" }}
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="menu-right pull-right">
                    {/*Top Navigation Bar Component*/}
                    <NavBar />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      );
  }

  render() {
	const { authenticated } = this.props;
	return (
		<div>
	{authenticated ? this.renderMobileNav() : this.renderNoMobileNav()}
	</div>
		
	)
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.authentication.authenticated
  };
}

export default connect(mapStateToProps, { changeCurrency })(HeaderOne);

var module = {
    fn: {
        locale: 'fn',
        messages: {
            topbar_title: 'Bienvenue dans notre magasin {theme_name}',
            call_us: 'Appelez nous',
            home: 'maison',
            features: 'fonctionnalités',
            new: 'Nouveau',
            blog_left_sidebar: 'barre latérale gauche',
            blog_right_sidebar: 'barre latérale droite',
            blog_detail: 'détail du blog',
            category_left_sidebar: 'barre latérale gauche',
            category_right_sidebar: 'barre latérale droite',
            category_no_sidebar: 'sans encadré',
            products: 'des produits',
            left_sidebar: 'Barre latérale degauche',
            right_sidebar: 'Barre latérale droite',
            no_sidebar: 'pas de barre latérale',
            three_col_thumbnail_left: '3 vignettes gauche',
            three_col_thumbnail_right: '3 vignette droite',
            thumbnail_below: 'vignette ci_dessous',
            accordian_details: 'détails accordéon',
            thumbnail_left: 'vignette à gauche',
            thumbnail_right: 'vignette droite',
            vertical_tab: 'onglet vertical',
            pages: 'des pages',
            about_us: 'à propos de nous',
            lookbook: 'lookbook',
            login: 'sidentifier',
            register: 'registre',
            logout: 'Connectez _ Out',
            search: 'chercher',
            wishlist: 'liste de souhaits',
            cart: 'Chariot',
            collection: 'collection',
            forget_password: 'mot de passe oublié',
            contact: 'contact',
            checkout: 'check_out',
            compare: 'comparer',
            order_success: 'succès de la',
            dashboard: 'Tableau de bord',
            FAQ: 'FAQ',
            shop: 'Boutique',
            mens_fashion: 'la mode masculine',
            sports_wear: 'vêtements de sport',
            top: 'Haut',
            bottom: 'bas',
            ethic_wear: 'usure éthique',
            shirts: 'chemises',
            women_fashion: 'la mode des femmes',
            dresses: 'les robes',
            skirts: 'jupes',
            westarn_wear: 'usure occidentale',
            ethnic_wear: 'tenue éthnique',
            sport_wear: 'vêtement de sport',
            bottom_wear: 'usure du bas',
            kids_fashion: 'mode pour enfants',
            accessories: 'Accessories',
            fashion_jewellery: 'bijoux fantaisie',
            caps_and_hats: 'casquettes et chapeaux',
            precious_jewellery: 'bijoux précieux',
            necklaces: 'colliers',
            earrings: 'des boucles doreilles',
            rings_wrist_wear: 'bagues et vêtements',
            men_accessories: 'accessoires pour hommes',
            ties: 'liens',
            cufflinks: 'boutons de manchette',
            pockets_squares: 'poches à carreaux',
            helmets: 'casques',
            scarves: 'écharpes',
            phone_cases: 'Coque de téléphone',
            my_account: 'Mon compte'
        }
    },
    en: {
        locale: 'en_US',
        messages: {
            topbar_title: 'Welcome to our gift giving platform',
            call_us: 'Call Us',
            home: 'home',
            features: 'features',
            new: 'new',
            blog_left_sidebar: 'blog(left_sidebar)',
            blog_right_sidebar: 'blog(right_sidebar)',
            blog_detail: 'blog detail',
            category_left_sidebar: 'category(left_sidebar)',
            category_right_sidebar: 'category(right_sidebar)',
            category_no_sidebar: 'category(no_sidebar)',
            products: 'products',
            left_sidebar: 'left sidebar',
            right_sidebar: 'right sidebar',
            no_sidebar: 'no sidebar',
            three_col_thumbnail_left: 'Thumbnail left',
            three_col_thumbnail_right: 'Thumbnail right',
            thumbnail_below: 'thumbnail below',
            accordian_details: 'accordian details',
            thumbnail_left: 'thumbnail left',
            thumbnail_right: 'thumbnail right',
            vertical_tab: 'vertical tab',
            pages: 'pages',
            about_us: 'about us',
            lookbook: 'lookbook',
            login: 'login',
            register: 'register',
            logout: 'Logout',
            search: 'search',
            wishlist: 'wishlist',
            cart: 'cart',
            collection: 'collection',
            forget_password: 'forget password',
            contact: 'contact',
            checkout: 'checkout',
            compare: 'compare',
            order_success: 'order_success',
            dashboard: 'Dashboard',
            FAQ: 'FAQ',
            shop: 'Shop',
            mens_fashion: 'mens fashion',
            top: 'top',
            bottom: 'bottom',
            ethic_wear: 'ethic wear',
            sports_wear: 'sports wear',
            shirts: 'shirts',
            women_fashion: 'womens fashion',
            dresses: 'dresses',
            skirts: 'skirts',
            westarn_wear: 'westarn wear',
            ethnic_wear: 'ethnic wear',
            sport_wear: 'aport wear',
            bottom_wear: 'bottom wear',
            kids_fashion: 'kidss fashion',
            accessories: 'Accessories',
            fashion_jewellery: 'Fashion Jewellery',
            caps_and_hats: 'caps and hats',
            precious_jewellery: 'precious jewellery',
            necklaces: 'necklaces',
            earrings: 'earrings',
            rings_wrist_wear: 'rings & wrist wear',
            men_accessories: 'mens accessories',
            ties: 'ties',
            cufflinks: 'cufflinks',
            pockets_squares: 'pockets squares',
            helmets: 'helmets',
            scarves: 'scarves',
            phone_cases: 'phone cases',
            my_account: 'My Account'
        }
    }

}

export default module;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import cookie from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Process from '../../components/common/process';
import {
  faRingsWedding,
  faBirthdayCake,
  faGlassCheers,
  faHeartCircle,
  faTruckMoving,
  faBaby,
  faHandshake,
  faGraduationCap,
  faHatSanta,
  faGift,
  faCheck,
} from '@fortawesome/pro-solid-svg-icons';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class AllCollections extends Component {
  useStyles() {
    return makeStyles(theme => ({
      card: {
        display: 'flex',
        flexDirection: 'column',
        flex: 8,
      },
      content: {
        flexDirection: 'row',
      },
    }));
  }

  constructor(props) {
    super(props);

    this.state = {
      collections: [],
    };
  }

  componentWillMount() {
    this.getCollections();
  }

  getCollections() {
    axios
      .get(
        `${REACT_APP_API_URL}/api/auth/collection/${localStorage.getItem(
          'userId',
        )}`,
        {
          headers: { Authorization: cookie.load('token') },
        },
      )
      .then(response => {
        this.setState({ collections: response.data });
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const collections = this.state.collections;
    const collectionTypes = [
      {
        name: 'Birthday',
        icon: faBirthdayCake,
      },
      {
        name: 'Engagement',
        icon: faGlassCheers,
      },
      {
        name: 'Wedding',
        icon: faRingsWedding,
      },
      {
        name: 'Anniversary',
        icon: faHeartCircle,
      },
      {
        name: 'New Home',
        icon: faTruckMoving,
      },
      {
        name: 'Baby Shower',
        icon: faBaby,
      },
      {
        name: 'Graduation',
        icon: faGraduationCap,
      },
      {
        name: 'Leaving',
        icon: faHandshake,
      },
      {
        name: 'Secret Santa',
        icon: faHatSanta,
      },
      {
        name: 'Other',
        icon: faGift,
      },
    ];
    return (
      <div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#9B7DD2',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff' }}>{'My Collections'}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="blog p-t-0">
          <div className="container">
            <div className="row">
              <Process step={0} topSpacing />
              <div className="col-lg-8">
                <div className="dashboard-right">
                  <div
                    className="col-md-12"
                    style={{ height: 'auto', paddingBottom: '20px' }}
                  >
                    {collections.reverse().map(function(item, i) {
                      const faIcon = collectionTypes.find(type => {
                        return type.name === item.collectionType;
                      }).icon;
                      return (
                        <div
                          className="col-md-12"
                          style={{
                            alignItems: 'center',
                            padding: 0,
                            marginTop: 0,
                            justifyContent: 'center',
                          }}
                        >
                          <Link
                            to={`${process.env.PUBLIC_URL}/collection/${item.collectionId}`}
                          >
                            <div
                              style={{
                                flexDirection: 'row',
                                display: 'flex',
                                flex: 10,
                                paddingTop: 10,
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  width: '40%',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: item.completed
                                    ? '#33A634'
                                    : '#9374CF',
                                }}
                              >
                                <span
                                  style={{
                                    display: 'block',
                                    height: '70px',
                                    width: '70px',
                                    lineHeight: '70px',
                                    borderRadius: '35px',
                                    borderWidth: 3,
                                    backgroundColor: item.completed
                                      ? '#33A634'
                                      : '#9374CF',

                                    borderColor: '#9374CF',
                                    textAlign: 'center',
                                    fontSize: '2.2em',
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={item.completed ? faCheck : faIcon}
                                    color={
                                      item.completed ? '#C5F7C3' : '#D3C3F7'
                                    }
                                  />
                                </span>
                                <span
                                  style={{
                                    position: 'absolute',
                                    right: '20px',
                                  }}
                                >
                                  <i
                                    className="fa fa-chevron-right"
                                    style={{
                                      fontSize: '1.5em',
                                      color: '#3DACFF',
                                    }}
                                  ></i>
                                </span>
                              </div>
                              <CardContent
                                style={{
                                  width: '100%',
                                  backgroundColor: '#F7F7F7',
                                }}
                              >
                                <Typography
                                  component="h6"
                                  variant="h6"
                                  style={{
                                    color: '#222222',
                                    textAlign: 'left',
                                    fontSize: '1.2em',
                                  }}
                                >
                                  {item.collectionName}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="textSecondary"
                                  style={{
                                    textAlign: 'left',
                                    fontSize: '1.2em',
                                  }}
                                >
                                  £{item.amountPledged.toFixed(2) || 0} / £
                                  {item.value.toFixed(2)}
                                </Typography>
                              </CardContent>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default AllCollections;

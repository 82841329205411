import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import './dropdown-style.css';
import { withRouter } from 'react-router-dom';
import { editCollection } from '../../actions/index';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import cookie from 'react-cookie';

const form = reduxForm({
  form: 'collection',
});

const options = [
  'Birthday',
  'Engagement',
  'Wedding',
  'Graduation',
  'Anniversary',
  'Leaving',
  'New Home',
  'Baby Shower',
  'Secret Santa',
  'Other',
];

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class EditCollection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionData: [],
      included: true,
    };
  }

  componentDidMount() {
    this.getCollection();
  }

  getCollection() {
    const { match = {} } = this.props;
    const id = match.params.id;
    axios
      .get(`${REACT_APP_API_URL}/api/auth/collection/single/${id}`, {
        headers: { Authorization: cookie.load('token') },
      })
      .then(response => {
        console.log(response);
        this.setState({
          collectionData: response.data,
          collectionUserId: response.data.userId,
          collectionType: response.data.collectionType,
        });
        this.props.change('noOfPeople', response.data.noOfPeople);
        this.props.change('value', response.data.value);
        this.props.change('collectionType', response.data.collectionType);
        this.props.change('suggestedAmount', response.data.suggestedAmount);
        this.props.change(
          'collectionDescription',
          response.data.collectionDescription,
        );
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleFormSubmit(formProps) {
    const { collectionData } = this.state;
    formProps.collectionId = collectionData.collectionId;
    formProps.userId = localStorage.getItem('userId');
    formProps.collectionType = this.state.collectionType;
    formProps.collectionName = collectionData.collectionName;

    this.props.editCollection(formProps, this.props.history);
  }

  onSelect(props) {
    this.setState({ collectionType: props.value });
  }

  render() {
    const { handleSubmit } = this.props;
    const { collectionData } = this.state;

    return (
      <div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#AB95D2',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff' }}>
                    {collectionData.collectionName}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Forget Password section*/}
        <section className=" contact-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <form
                  className="theme-form"
                  onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
                >
                  <div className="form-row">
                    <div className="col-md-6">
                      <label htmlFor="email">
                        Occasion <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Dropdown
                        name="collectionType"
                        options={options}
                        onChange={this.onSelect.bind(this)}
                        value={collectionData.collectionType}
                        placeholder="Select an option"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="review">
                        Total Fund Goal (£){' '}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        name="value"
                        className="form-control"
                        type="number"
                        placeholder="Enter gift total"
                        component="input"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="review">
                        How much would you like each person to contribute? (£){' '}
                        <span style={{ color: '#B7B9BC' }}>(optional)</span>
                      </label>
                      <Field
                        name="suggestedAmount"
                        className="form-control"
                        component="input"
                        type="number"
                        placeholder="Enter amount"
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">
                        Description{' '}
                        <span style={{ color: '#B7B9BC' }}>(optional)</span>
                      </label>
                      <Field
                        name="collectionDescription"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Collection Description (Optional)"
                      />
                    </div>
                    <div
                      style={{
                        paddingBottom: 20,
                        paddingRight: 10,
                        width: '100%',
                      }}
                    >
                      <span
                        style={{
                          float: 'right',
                        }}
                      >
                        <span style={{ color: 'red' }}>*</span> required fields
                      </span>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="btn"
                        type="submit"
                        style={{
                          width: '100%',
                          height: 60,
                          float: 'right',
                          display: 'inline-block',
                          backgroundColor: '#3DACFF',
                          color: 'white',
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
  };
}

export default withRouter(
  connect(mapStateToProps, { editCollection })(form(EditCollection)),
);

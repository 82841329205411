import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { registerUser } from '../../actions/index';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

const form = reduxForm({
  form: 'register',
});
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#E33E7F',
    },
  },
});
const textFieldStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  color: 'white',
  borderBottomColor: 'white',
  backgroundColor: 'rgba(148,116,208, 0.4)',
  paddingLeft: 10,
  fontFamily: 'Quicksand',
};

const textFieldInputStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  color: 'white',
  borderBottomColor: 'white',
  paddingLeft: 10,
  fontFamily: 'Quicksand',
};

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      divName: 'RTL',
      colorPick: false,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      confirmPassword: null,
      accept: false,
      error: null,
    };
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      this.setState({ isLoading: false });
    });
  };

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  ChangeRtl(divName) {
    if (divName === 'RTL') {
      document.body.classList.add('rtl');
      this.setState({ divName: 'LTR' });
    } else {
      document.body.classList.remove('rtl');
      this.setState({ divName: 'RTL' });
    }
  }
  componentDidMount() {
    setTimeout(function() {
      document.querySelector('.holder').style = 'display: none';
    }, 4000);
  }

  showHideColor() {
    this.setState({ colorPick: !this.state.colorPick });
  }

  changeColor(color) {
    document
      .getElementById('color')
      .setAttribute(
        'href',
        `${process.env.PUBLIC_URL}/assets/css/` + color + `.css`,
      );
  }

  handleFormSubmit(formProps) {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    } = this.state;
    if (firstName === null) {
      this.setState({ error: 'Enter your first name' });
    } else if (!lastName) {
      this.setState({ error: 'Enter your last name' });
    } else if (!email) {
      this.setState({ error: 'Enter your email' });
    } else if (!password) {
      this.setState({ error: 'Enter a password' });
    } else if (!confirmPassword) {
      this.setState({ error: 'Please confirm password' });
    } else if (password !== confirmPassword) {
      this.setState({ error: 'Passwords do not match' });
    } else if (!this.state.accept) {
      this.setState({
        error: 'To continue, you must accept terms and conditions',
      });
    } else {
      formProps.firstName = firstName;
      formProps.lastName = lastName;
      formProps.email = email;
      formProps.password = password;
    }
    this.props.registerUser(formProps, this.props.history);
  }

  handleFirstName(event) {
    this.setState({ firstName: event.target.value });
  }
  handleLastName(event) {
    this.setState({ lastName: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handlePassword(event) {
    this.setState({ password: event.target.value });
  }
  handlePasswordConfirm(event) {
    this.setState({ confirmPassword: event.target.value });
  }

  renderErrorMessage() {
    const { error } = this.state;

    if (error) {
      return (
        <React.Fragment>
          <div style={{ marginTop: 10, color: '#edcf2f', textAlign: 'center' }}>
            <i
              className="fa fa-exclamation-triangle"
              style={{ marginRight: 10 }}
            />{' '}
            {this.state.error}
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div style={{ marginTop: 10, color: '#edcf2f', textAlign: 'center' }}>
          <i
            className="fa fa-exclamation-triangle"
            style={{ display: 'none' }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <MuiThemeProvider muiTheme={theme}>
        <div>
          <div
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/splash.jpg)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div
              style={{
                backgroundImage:
                  'linear-gradient(rgba(110,71,186, 0.85), rgba(110,71,186, 0.85))',
                backgroundSize: 'cover',
                height: '100%',
                width: '100%',
                paddingTop: '5vh',
              }}
            >
              {/*Login settion*/}
              <section className="login-page section-b-space">
                <div className="container">
                  <div
                    className="row"
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className="col-lg-6">
                      <div style={{ marginLeft: 20 }}>
                        <img
                          alt="gyphto-logo"
                          width="20%"
                          src={`${process.env.PUBLIC_URL}/assets/images/logo-g.png`}
                        />
                        <span
                          style={{
                            marginLeft: 20,
                            fontFamily: 'Quicksand',
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: 'normal',
                            fontStyle: 'normal',
                          }}
                        >
                          Create Account
                        </span>
                      </div>
                      {this.renderErrorMessage()}
                      <div
                        style={{
                          marginLeft: 20,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      >
                        <form className="theme-form">
                          <div
                            className="form-group"
                            style={{ marginBottom: 20 }}
                          >
                            <TextField
                              fullWidth
                              label="First Name"
                              InputLabelProps={{
                                style: textFieldInputStyle,
                              }}
                              InputProps={{
                                style: textFieldStyle,
                              }}
                              onChange={event => this.handleFirstName(event)}
                            />
                          </div>

                          <div
                            className="form-group"
                            style={{ marginBottom: 20 }}
                          >
                            <TextField
                              fullWidth
                              label="Last Name"
                              InputLabelProps={{
                                style: textFieldInputStyle,
                              }}
                              InputProps={{
                                style: textFieldStyle,
                              }}
                              onChange={event => this.handleLastName(event)}
                            />
                          </div>

                          <div
                            className="form-group"
                            style={{ marginBottom: 20 }}
                          >
                            <TextField
                              fullWidth
                              label="Email"
                              InputLabelProps={{
                                style: textFieldInputStyle,
                              }}
                              InputProps={{
                                style: textFieldStyle,
                              }}
                              onChange={event => this.handleEmail(event)}
                            />
                          </div>

                          <div
                            className="form-group"
                            style={{ marginBottom: 20 }}
                          >
                            <TextField
                              type="password"
                              fullWidth
                              label="Password"
                              InputLabelProps={{
                                style: textFieldInputStyle,
                              }}
                              InputProps={{
                                style: textFieldStyle,
                              }}
                              onChange={event => this.handlePassword(event)}
                            />
                          </div>

                          <div
                            className="form-group"
                            style={{ marginBottom: 20 }}
                          >
                            <TextField
                              type="password"
                              fullWidth
                              label="Confirm Password"
                              InputLabelProps={{
                                style: textFieldInputStyle,
                              }}
                              InputProps={{
                                style: textFieldStyle,
                              }}
                              SelectProps={{
                                style: textFieldStyle,
                              }}
                              onChange={event =>
                                this.handlePasswordConfirm(event)
                              }
                            />
                          </div>
                          <div
                            className="form-group"
                            style={{ marginBottom: 20 }}
                          >
                            <Checkbox
                              checked={this.state.accept}
                              value="checkedA"
                              inputProps={{ 'aria-label': 'Checkbox A' }}
                              onChange={() =>
                                this.setState({
                                  accept: !this.state.accept,
                                })
                              }
                              color="secondary"
                              style={{ paddingLeft: 0 }}
                            />
                            <span
                              style={{
                                color: 'white',
                                fontFamily: 'Quicksand',
                              }}
                            >
                              I accept{' '}
                              <a
                                href="/pages/terms"
                                style={{
                                  color: '#aca9fc',
                                  textDecoration: 'underline',
                                }}
                              >
                                terms and conditions
                              </a>
                            </span>
                          </div>

                          <div></div>
                        </form>
                        <button
                          onClick={this.handleFormSubmit.bind(this)}
                          className="btn btn-solid"
                          style={{
                            fontFamily: 'Quicksand',
                            background: '#9471d2',
                            borderColor: '#9471d2',
                            width: '100%',
                            display: 'block',
                          }}
                        >
                          Sign up
                        </button>
                        <br />
                        <a
                          href="/pages/login"
                          className="btn btn-solid"
                          style={{
                            fontFamily: 'Quicksand',
                            background: 'rgb(148, 113, 210, 0)',
                            borderColor: '#9471d2',
                            color: '#9471d2',
                            width: '100%',
                            display: 'block',
                            marginBottom: '400px',
                          }}
                        >
                          Already have an account?
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.authentication.errorMessage,
  };
}

export default withRouter(
  connect(mapStateToProps, { registerUser })(form(Register)),
);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'smartmenus';
import { logoutUser } from '../../../../actions/index';
import { connect } from 'react-redux';
class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: { right: '0px' },
    };
  }

  componentWillMount() {
    console.log(this.props);
    $(function() {
      $('#main-menu').smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
      });
    });
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: '-410px' } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: '-300px' } });
    }
  }

  openNav() {
    this.setState({ navClose: { right: '0px' }, navOpened: true });
  }
  closeNav() {
    this.setState({ navClose: { right: '-410px' }, navOpened: false });
  }

  menuHover() {
    var contentwidth = window.innerWidth;
    if (contentwidth > 1200) {
      document.querySelector('.sm-horizontal').classList.add('hover-unset');
    }
  }

  handleLogout() {
    this.props.logoutUser();
  }

  render() {
    const { authenticated } = this.props;
    const { navOpened } = this.state;
    return (
      <div>
        <nav id="main-nav">
          <div className="toggle-nav" onClick={this.openNav.bind(this)}>
            <i className="fa fa-bars sidebar-bar"></i>
          </div>
          {/*Horizontal menu*/}
          <ul
            id="main-menu"
            className="sm pixelstrap sm-horizontal"
            style={this.state.navClose}
          >
            <li>
              <div
                className="mobile-back text-right"
                onClick={navOpened && this.closeNav.bind(this)}
              >
                Back
                <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
              </div>
            </li>
            {authenticated && (
              <li className="mega">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  onClick={navOpened && this.closeNav.bind(this)}
                >
                  {'home'}
                </Link>
              </li>
            )}
            {authenticated && (
              <li className="mega">
                <Link
                  to={`${process.env.PUBLIC_URL}/all-collections`}
                  onClick={navOpened && this.closeNav.bind(this)}
                >
                  {'my collections'}
                </Link>
              </li>
            )}
            {authenticated && (
              <li className="mega">
                <Link
                  to={`${process.env.PUBLIC_URL}/create`}
                  onClick={navOpened && this.closeNav.bind(this)}
                >
                  {'create a collection'}
                </Link>
              </li>
            )}
            {authenticated && (
              <li className="mega">
                <Link
                  to={`${process.env.PUBLIC_URL}/account`}
                  onClick={navOpened && this.closeNav.bind(this)}
                >
                  {'my account'}
                </Link>
              </li>
            )}
            {authenticated && (
              <li className="mega" onClick={() => this.handleLogout()}>
                <Link
                  to={`${process.env.PUBLIC_URL}/pages/login`}
                  onClick={navOpened && this.closeNav.bind(this)}
                >
                  {'logout'}
                </Link>
              </li>
            )}
            {!authenticated && (
              <li className="mega">
                <Link
                  to={`${process.env.PUBLIC_URL}/pages/register`}
                  onClick={navOpened && this.closeNav.bind(this)}
                >
                  {'create an account'}
                </Link>
              </li>
            )}
            {!authenticated && (
              <li className="mega">
                <Link
                  to={`${process.env.PUBLIC_URL}/pages/login`}
                  onClick={navOpened && this.closeNav.bind(this)}
                >
                  {'login'}
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.authentication.authenticated,
  };
}

export default connect(mapStateToProps, { logoutUser })(NavBar);

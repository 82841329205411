import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#9B7DD2',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff', textTransform: null }}>
                    {'Privacy Policy'}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Dashboard section*/}
        <section className="faq-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12" style={{ lineHeight: 2 }}>
                <p style={{ lineHeight: 2 }}>
                  This privacy policy discloses the privacy practices for Giftit
                  Limited, a company incorporated in Scotland with company
                  number SC578669 and having its registered office at 272 Bath
                  Street, Glasgow, G2 4JR ("we", "us", "our"). Our website is
                  www.gyphto.com (the "Site") and our mobile app is called
                  Gyphto.{' '}
                </p>
                <p style={{ lineHeight: 2 }}>
                  By accessing the Site, downloading the APP and/or by
                  submitting personal data manually or in electronic form to us,
                  you give your consent that all personal data that you submit
                  will be processed by us in the manner and for the purposes
                  described below. By sharing information with us, we are able
                  to provide an even better service to you. As you use our
                  services, we want you to be clear how we’re using information
                  and the ways in which you can protect your privacy. Please
                  read the following carefully to understand our views and
                  practices regarding your personal data and how we will treat
                  it.
                </p>
                <p style={{ lineHeight: 2 }}>
                  If you have any questions at all about this policy, the APP or
                  the Site or about how we use and process your personal
                  information, please do not hesitate to contact us by e-mail at
                  iqbal@gyphto.com or, alternatively, in writing to Giftit
                  Limited, 272 Bath Street, Glasgow, G2 4JR.
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  1. Information collection and use
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.1 We are committed to protecting the privacy of the users of
                  our Site and our App. We are the sole owner of the information
                  collected on the Site and by using the App. We will not sell,
                  share, or rent this information to others in ways different
                  from those disclosed in this statement.
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.2 We collect information from you at several different
                  points on the Site and when you download and use the App. We
                  use various analytic tools which track matters such as your
                  use of the Site, the type of your device in use and location
                  and length of time using the App.
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.3 By submitting your information you consent to the use of
                  that information as set out in this policy. If we change our
                  privacy policy we will post the changes on this page, and may
                  place notices on other pages of the Site, so that you may be
                  aware of the information we collect and how we use it at all
                  times. Continued use of the Site and/or the App will
                  constitute your agreement to any such changes. It is your
                  responsibility to review the Site from time to time to ensure
                  you are aware of any changes to this policy.
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.4 The data we collect from you may be transferred to, and
                  stored at, a destination located outside the UK but within the
                  European Economic Area ("EEA"). By providing information to
                  the Site you expressly consent to such information being
                  transferred or stored outside the UK but within the EEA.
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.5 Unfortunately, the transmission of information via the
                  internet is not completely secure and although we take all
                  reasonable steps to protect your personal data, we cannot
                  guarantee the security of it. Any transmission of personal
                  data to the Site is at your own risk.
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.6 We will only keep your personal data for as long as
                  necessary to allow you to use the Site and/or APP.
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.7 Where you authorise us to do so, we will access data held
                  on any social media account or other system that you choose to
                  sync with the APP or the Site.
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.8 If you give us information on behalf of someone else, you
                  confirm that the other person has appointed you to act on
                  his/her behalf and has agreed that you can:
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.6.1 give consent on his/her behalf to the processing of
                  his/her personal data;
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.6.2 receive on his/her behalf any data protection notices;
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.6.3 give consent to the transfer of his/her personal data
                  abroad; and
                </p>
                <p style={{ lineHeight: 2 }}>
                  1.6.4 give consent to the processing of his/her personal
                  information.
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  2 Public (unregistered) users
                </p>
                <p style={{ lineHeight: 2 }}>
                  We will not capture data about public (unregistered) users of
                  the Site, other than your IP address and session details such
                  as pages viewed and type of browser used. This information is
                  automatically logged by the web server and will be used to
                  evaluate the use and performance of the Site.
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  3 Registration
                </p>
                <p style={{ lineHeight: 2 }}>
                  In order to use the Site or download the App, you must first
                  complete the registration form and all details required in it.
                  During registration you are required to give your contact
                  information (such as name, email address, postcode, gender and
                  password). This information is used to contact you about those
                  services on the Site in which you have expressed an interest.
                  It is optional for you to provide other personal information,
                  but this is encouraged so we can provide a more personalised
                  experience of the services available through the APP and the
                  Site.
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  4 Sharing
                </p>
                <p style={{ lineHeight: 2 }}>
                  4.1 We may share (whether or not for payment) aggregated
                  demographic information with our partners and clients for
                  marketing purposes. This is not linked to any personal
                  information that can identify any individual person.
                </p>
                <p style={{ lineHeight: 2 }}>
                  4.2 We may also use such aggregated information and statistics
                  for monitoring the Site usage in order to help us develop the
                  Site and our services and may provide such aggregate
                  information to third parties.
                </p>
                <p style={{ lineHeight: 2 }}>
                  4.3 We may also require to share personal data with parties
                  who provide services to us in connection with the Site, such
                  as payment service providers. The recipients use such data
                  only for the purposes of the services they provide to us and
                  are not authorised to use your data in any other way,{' '}
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  5 Cookies
                </p>
                <p style={{ lineHeight: 2 }}>
                  5.1 A cookie is a piece of data stored on your hard drive
                  containing information about you. Usage of a cookie is in no
                  way linked to any personally identifiable information while on
                  the Site. Once you close your browser, the cookie simply
                  terminates. For instance, by setting a cookie on the Site, you
                  would not have to log in a password more than once, thereby
                  saving time while on the Site. If you reject the cookie, you
                  may not be able to use every function of the Site. Cookies can
                  also enable us to track and target your interests to enhance
                  your experience of the Site.{' '}
                </p>
                <p style={{ lineHeight: 2 }}>
                  5.2 Information collected from cookies and other technologies,
                  like pixel tags, will be used to improve your user experience
                  and the overall quality of our services. For example, we will
                  be able to have our services appear in the language you prefer
                  and provide you with specific tailored ads.
                </p>
                <p style={{ lineHeight: 2 }}>
                  5.3 By accepting this policy you are providing explicit
                  consent to our use of cookies. If you wish to opt out please
                  adjust your web browser settings accordingly.
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  6 Log files
                </p>
                <p style={{ lineHeight: 2 }}>
                  We use IP addresses to analyse trends, administer the Site and
                  gather broad demographic information for aggregate use. IP
                  addresses are not linked to personally identifiable
                  information.
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  7 Links
                </p>
                <p style={{ lineHeight: 2 }}>
                  The Site contains links to other sites. Please be aware that
                  we are not responsible for the privacy practices of other
                  sites. We encourage you to be aware when you leave the Site
                  and to read the privacy policies of each and every website
                  that collects personally identifiable information. This
                  privacy policy applies solely to information collected by us
                  on the Site.
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  8 Direct mailings
                </p>
                <p style={{ lineHeight: 2 }}>
                  8.1 We may send out newsletters, daily emails or alerts to
                  you. We may also wish to provide you with information about
                  special features of our Site or any other service or products
                  we think may be of interest to you. This is an opt-in email
                  option selected when you register to the Site. At any time you
                  can alter your personal profile on the Site to disable any
                  further emails.
                </p>
                <p style={{ lineHeight: 2 }}>
                  8.2 From time to time the Site may request information from
                  you via surveys or contests. Participation in these surveys or
                  contests is completely voluntary and you therefore have a
                  choice whether or not to disclose this information.
                  Information requested may include contact information (such as
                  name and shipping address), and demographic information (such
                  as postcode or age level). Contact information will be used to
                  notify the winners and award prizes. Survey information will
                  be used for purposes of monitoring or improving the use of and
                  satisfaction with this site.
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  9 Data Protection
                </p>
                <p style={{ lineHeight: 2 }}>
                  9.1 We make every effort to ensure that our practices,
                  procedures and systems comply with the relevant data
                  protection legislation.
                </p>
                <p style={{ lineHeight: 2 }}>
                  9.2 We shall only use your personal data in accordance with
                  the legal requirements, principles and best practice
                  recommendations by current legislation, which grants you
                  various rights in respect of the processing of your personal
                  data.
                </p>
                <p style={{ lineHeight: 2 }}>
                  9.3 For more details about your rights regarding personal
                  data, the rules we have to adhere to in collecting and storing
                  your information, and how you can check your data records,
                  please visit www.dataprotection.gov.uk.
                </p>
                <p style={{ lineHeight: 2 }}>
                  9.4 You have a right to receive a copy of the information we
                  hold about you by sending your request in writing to us at
                  Giftit Limited, 272 Bath Street, Glasgow, G2 4JR.
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  10 Updating/removal of personal information
                </p>
                <p style={{ lineHeight: 2 }}>
                  10.1 If any information regarding you requires to be
                  corrected, updated or changed it is your responsibility to
                  advise us of the required amendments.
                </p>
                <p style={{ lineHeight: 2 }}>
                  10.2 If you inform us that you no longer desire to use the
                  APP, we shall correct, update or remove your personal data
                  provided to us. Please note an archive copy of any information
                  provided to us may be retained by us for our records and for
                  audit purposes.
                </p>
                <p style={{ lineHeight: 2 }}>
                  10.3 [You may change your preferences at any time or request
                  that we stop sending you email communications by contacting us
                  at iqbal@gyphto.com. There is also an 'unsubscribe' button at
                  the bottom of every email communication we send.]
                </p>
                <p
                  style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}
                >
                  11 Contact
                </p>
                <p style={{ lineHeight: 2 }}>
                  If you have any questions at all about this policy or the
                  Site, or about how we use and process your personal
                  information, please do not hesitate to contact us by e-mail at
                  iqbal@gyphto.com or, alternatively, in writing to Giftit
                  Limited at 272 Bath Street, Glasgow, G2 4JR.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PrivacyPolicy);

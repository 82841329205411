import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Link, withRouter} from 'react-router-dom';

class TermsAndConditions extends Component {
    render (){
        return (
            <div>
                 <div
              className="breadcrumb-section"
              style={{
                backgroundColor: "#9B7DD2",
                height: 60,
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="page-title">
                      <h2 style={{ color: "#fff", textTransform: null }}>
                        {"Terms and Conditions"}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                {/*Dashboard section*/}
                <section className="faq-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12" style={{ lineHeight: 2}}>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>1	Terms and conditions of website use</p>
                              <p style={{ lineHeight: 2 }}>Gyphto is the trading name of Giftit Limited which is a company incorporated in Scotland with company number SC578869 and having its registered office at 272 Bath Street, Glasgow, G2 4JR ("Giftit , "we", "us", "our"). Our website is Gyphto.com (the "site") and our mobile app is called Gyphto (the "APP").</p>
                              <p style={{ lineHeight: 2 }}>Set out below are the terms and conditions (the "Terms") you are agreeing to when you use (however you access it including from a mobile device) the site and/or the APP.  These Terms cover (i) the download of the APP through the Site or from any App Store and (ii) use of the site.</p>
                              <p style={{ lineHeight: 2 }}>You should read, and ensure that you understand, all of the Terms prior to using the site and/or the APP. If you do not agree to be bound by these Terms then you should not use the site and/or download or use the APP.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>2	Definitions</p>
                              <p style={{ lineHeight: 2 }}>In these Terms the following expressions shall, unless the context otherwise requires, have the following meanings:</p>
                              <p style={{ lineHeight: 2 }}>"Collection Monies" means the amount raised by a Collection Organiser through our site/APP in relation to a Collection;</p>
                              <p style={{ lineHeight: 2 }}>"Collection Organiser" means a User who has set up a Collection on the site or through the APP;</p>
                              <p style={{ lineHeight: 2 }}>"Collection(s)" has the meaning given in 6.1;</p>
                              <p style={{ lineHeight: 2 }}>"Confirmation" has the meaning given in 7.2;</p>
                              <p style={{ lineHeight: 2 }}>"Intellectual Property Rights" means inventions, patent applications, patents, design rights, copyrights, trade marks, service marks, trade names, domain name rights, mask work rights, know-how and other trade secret rights, and all other intellectual property rights, derivatives thereof, and forms of protection of a similar nature anywhere in the world; </p>
                              <p style={{ lineHeight: 2 }}>"Partner Sites" are as set out on our site/APP from time to time;</p>
                              <p style={{ lineHeight: 2 }}>"Service(s)" means the services operated and maintained by us, accessible via our site, or ancillary online or offline products and services provided to you by us, to which you are being granted access under these Terms; and</p>
                              <p style={{ lineHeight: 2 }}>"User(s)" means individuals who use the Service and have been supplied user identifications and passwords by you (or by us at your request).</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>3	Use of site/APP</p>
                              <p style={{ lineHeight: 2 }}>3.1	It is free to download and use the APP.</p>
                              <p style={{ lineHeight: 2 }}>3.2	You are entitled to uninstall the APP at any time using the uninstall option within the APP. </p>
                              <p style={{ lineHeight: 2 }}>3.3	We reserve the right at our sole discretion to restrict access to some or all parts of our site/APP by persons or organisations seeking to access our site. </p>
                              <p style={{ lineHeight: 2 }}>3.4	You are responsible for making all arrangements necessary for you to have access to our site/APP.  You are responsible for ensuring that your computer system meets all relevant technical specifications necessary to use our site/APP and is compatible with our site/APP. You are also responsible for ensuring that all persons who access our site/APP through any facilities provided by you or over which you have control are aware of these Terms and that they comply with them.  </p>
                              <p style={{ lineHeight: 2 }}>3.5	Anyone can register to use our site/APP however you must set up an account in order to log in to our site/APP, the process which shall be detailed on the site/APP from time to time.</p>
                              <p style={{ lineHeight: 2 }}>3.6	Anyone can access our site/APP however in order to be able to contribute to a Collection you must be resident in and accessing our site/APP from a country supported by our payment service provider as detailed on the site/APP from time to time.</p>
                              <p style={{ lineHeight: 2 }}>3.7	We have used reasonable endeavours to ensure that our site/APP complies with UK laws. However, we make no representations that our site/APP or the Services are appropriate or available for viewing, access or use in locations outside of the UK. If you access our site/APP from other locations you do so at your own initiative and are responsible for compliance with all laws applicable to such location. If viewing, accessing or using our site/APP, the Services is contrary to or infringes any applicable law in your place of access or place of residence, you are not authorised to view, access or use our site/APP, the Services and you must exit immediately. If making available the Services in your place of access or place of residence or to you (by reason of nationality, residence or otherwise) is prohibited, the Services are not made available to you. You accept that if you are resident outside of the UK, you must satisfy yourself that you are lawfully able to use the Services.</p>
                              <p style={{ lineHeight: 2 }}>3.8	We shall use reasonable endeavours to ensure that our site/APP and any Services are available and that our site/APP operates properly at all times. </p>
                              <p style={{ lineHeight: 2 }}>3.9	We may suspend the availability or operation of our site/APP or the availability of any Services on a temporary or indefinite basis as we may in our sole discretion determine. </p>
                              <p style={{ lineHeight: 2 }}>3.10	We reserve the right to alter these Terms from time to time by posting new terms and conditions on the site/APP.   Your continued use of the site and/or the APP (or any part thereof) following such change will be deemed to be your acceptance of such change.  It is your responsibility to check regularly to determine if the Terms have been changed.  If you do not agree to any change to the Terms, you must stop using the site and the APP with immediate effect.</p>
                              <p style={{ lineHeight: 2 }}>3.11	We may make software owned or operated by third-party companies available to you. You must only use this software in accordance with the terms and conditions imposed by the third-party provider.</p>
                              <p style={{ lineHeight: 2 }}>3.12	If any provision of these Terms is held by any competent authority to be illegal, invalid or unenforceable in whole or in part then such provision shall be deemed to be omitted and the validity of the other provisions of these Terms shall not be affected.</p>
                              <p style={{ lineHeight: 2 }}>3.13	All liability is excluded to the maximum extent permitted by law including any implied terms.</p>
                              <p style={{ lineHeight: 2 }}>3.14	The exclusions of liability set out in these Terms shall not apply to any damages arising from death or personal injury caused by our negligence, or any of our employees or agents.</p>
                              <p style={{ lineHeight: 2 }}>3.15	Nothing in these Terms shall, whether directly or indirectly, give or be deemed to give any enforceable benefit of any kind to any third party as against us.</p>
                              <p style={{ lineHeight: 2 }}>3.16	Any failure or delay by us to enforce any of these Terms shall not be considered a waiver by us of our right to enforce such term or condition of these Terms.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>4	Your Obligations</p>
                              <p style={{ lineHeight: 2 }}>4.1	You are responsible for all activity on the User accounts and shall comply with all applicable laws and regulations in connection with use of the Service, including those related to data protection, international communications and the transmission of technical or personal data. </p>
                              <p style={{ lineHeight: 2 }}>4.2	You shall notify us immediately of any unauthorised use of any password or account or any other known or suspected breach of security.</p>
                              <p style={{ lineHeight: 2 }}>4.3	You must not: </p>
                              <p style={{ lineHeight: 2 }}>4.3.1	misuse our site/APP by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful including that which may damage, detrimentally interfere with, surreptitiously intercept or expropriate our systems, data or information;</p>
                              <p style={{ lineHeight: 2 }}>4.3.2	attempt to gain unauthorised access to our site/APP or any part of it, the servers on which our site is stored or any server, computer or database connected to our site, which you are not expressly entitled to access;</p>
                              <p style={{ lineHeight: 2 }}>4.3.3	use any robot, spider, other automatic device, or manual process to monitor or copy our site, or use any device, software or routine to bypass our robot exclusion headers, or interfere or attempt to interfere, with our site/APP;</p>
                              <p style={{ lineHeight: 2 }}>4.3.4	use an anonymising proxy to access our site/APP;</p>
                              <p style={{ lineHeight: 2 }}>4.3.5	attack our site/APP via a denial-of-service attack or a distributed denial-of service attack;</p>
                              <p style={{ lineHeight: 2 }}>4.3.6	take any action that imposes an unreasonable or disproportionately large load on our infrastructure, or that may cause us to lose any of the services from our service providers, including our internet or payment service providers; or</p>
                              <p style={{ lineHeight: 2 }}>4.3.7	do, or omit to do, or attempt to do or omit to do, any other act or thing which may interfere with the proper operation of our site/APP.</p>
                              <p style={{ lineHeight: 2 }}>4.4	Users are hereby granted a non-exclusive, non-transferable, right to use the Services, solely for your own personal use subject to these Terms.     </p>
                              <p style={{ lineHeight: 2 }}>4.5	You shall not license, sublicense, copy, modify, adapt, sell, resell, create derivative works from, transfer, assign, distribute or otherwise commercially exploit or make available in any way to any third party the Service.  </p>
                              <p style={{ lineHeight: 2 }}>4.6	If you breach this provision, we have the right to report any such breach to the relevant law enforcement authorities, and third parties whose interests may be endangered by your actions, and we will co-operate with those authorities and persons by disclosing your identity to them. In the event of such a breach, your right to use our site/APP will cease immediately.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>5	Charges and Fees</p>
                              <p style={{ lineHeight: 2 }}>5.1	You shall pay all fees or charges to the Company in accordance with the fees and charges specified in the Confirmation.</p>
                              <p style={{ lineHeight: 2 }}>5.2	All fees and charges payable to the Company on the terms specified in the Confirmation or otherwise shall be paid by you at the point of the Confirmation. </p>
                              <p style={{ lineHeight: 2 }}>5.3	All payments to the Company in accordance with these Terms and the Confirmation shall be made by electronic transfer to such bank account as the Company may direct from time to time.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>6	Collection Payment</p>
                              <p style={{ lineHeight: 2 }}>6.1	Our site/APP includes facilities whereby Users may make a payment to support group collections which have been arranged by the Collection Organisers ("Collections").</p>
                              <p style={{ lineHeight: 2 }}>6.2	Collections can be open to collect payment for any period as specified in the Collection, after which no further payments can be made to that Collection.</p>
                              <p style={{ lineHeight: 2 }}>6.3	Where a payment is made, it is made directly to the fund for the Collection Organiser. In respect of payments we are acting solely as the disclosed agent of the Collection Organiser, assisting with the collection and remission of the payment to those Collections. Full details of who Collection Organisers are, and their contact details, are set out upon our site/APP. In return for the making of a payment, the Collection Organisers may arrange for gifts to be purchased through one of our Partner Sites. Once a payment is made to a Collection it may not be cancelled or refunded and if you wish to do so this is a private matter between you and the Collection Organiser for which we have no responsibility or liability.</p>
                              <p style={{ lineHeight: 2 }}>6.4	Our liability is limited to the circumstances where we fail properly to assist with the collection and remission of payments. Where we fail properly to assist with the collection and remission of payments, our liability shall be limited to the amount of the relevant payment.</p>
                              <p style={{ lineHeight: 2 }}>6.5	If you are a Collection Organiser, once a Collection has ended, you can use the Collection Monies to purchase a gift through one of our Partner Sites. Any order which you place through one of our Partner Sites shall be governed by the terms and conditions of such Partner Site. </p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>7	Payment process </p>
                              <p style={{ lineHeight: 2 }}>7.1	You must submit to us a request to make a payment (a “Payment Request”). We may refuse your Payment Request for whatever reason we may in our sole discretion determine. Payment Requests may be submitted only through the form provided upon our site/APP. </p>
                              <p style={{ lineHeight: 2 }}>7.2	Upon receipt of your Payment Request we shall confirm receipt of such to you using online electronic means only. You are then required to indicate your acceptance by clicking upon the “I Accept” button provided within the body of our confirmation (your “Confirmation”), if you wish to make the payment detailed therein. We reserve the right to limit the period during which our confirmations remain valid. If our confirmation expires before you provide your Confirmation you may be required to recommence the procedure provided for in this section to make the relevant payment. </p>
                              <p style={{ lineHeight: 2 }}>7.3	Upon the provision of your Confirmation you will be forwarded to the website of our chosen payment services provider (the “Payment Website”) in order to remit to us the payment. You must remit the payment using the payment methods provided upon the Payment Website. You may not remit the payment using any other method. </p>
                              <p style={{ lineHeight: 2 }}>7.4	You acknowledge that the payment website is a website made available by a third party and as such we do not have control over its operation. We exclude all liability for any damages, loss, costs or expenses relating to or arising out of your use of or interaction with the Payment Website. </p>
                              <p style={{ lineHeight: 2 }}>7.5	In respect of the Payment Website you undertake and warrant:</p>
                              <p style={{ lineHeight: 2 }}>7.5.1	to abide by any terms and conditions relating to use of or interaction with the Payment Website;</p>
                              <p style={{ lineHeight: 2 }}>7.5.2	that any information submitted by you to the Payment Website will not be false, inaccurate or misleading; and</p>
                              <p style={{ lineHeight: 2 }}>7.5.3	not to engage or attempt to engage in any potentially fraudulent or unauthorised activity or any activity that does or may present a credit or fraud risk to us, the provider of the Payment Website or any third party.</p>
                              <p style={{ lineHeight: 2 }}>7.6	Our service providers may charge other fees to attend to payments. These will be set out on the Payment Website.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>8	Intellectual Property </p>
                              <p style={{ lineHeight: 2 }}>8.1	We own all right, title and interest, including all related Intellectual Property Rights, in and to our Service.</p>
                              <p style={{ lineHeight: 2 }}>8.2	Other than the right to use the Service as set out in these Terms, you acquire no right to use any of such items and have no right to any intellectual property right in them.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>9	Termination</p>
                              <p style={{ lineHeight: 2 }}>9.1	If you are a consumer, you have a right to cancel an agreement made over the internet without liability which lasts for fourteen (14) days following your entry into that agreement. If you exercise this right to cancel sums paid by you, you will be refunded using the same means you used to pay over those sums. Note this does not allow any person who has made a payment to a Collection to cancel it once it has been paid.</p>
                              <p style={{ lineHeight: 2 }}>9.2	When you agree to these Terms, as part of the process of registering to use our site/APP, you expressly request that we commence providing services to you. As such you will lose your right to cancel without liability. This means that whilst you may still cancel the agreement between you and us, some or all of the sums paid by you may be retained, and not refunded to you.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>10	Data protection</p>
                              <p style={{ lineHeight: 2 }}>Any personal information you supply to Giftit when using the APP and/or the site will be used by Giftit in accordance with its Privacy Policy which can be viewed <a href={`${process.env.PUBLIC_URL}/privacy-policy`}>here</a>.</p> 
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>11	Mobile provider</p>
                              <p style={{ lineHeight: 2 }}>11.1	You acknowledge that the terms of agreement with your respective mobile network provider ("Mobile Provider") will continue to apply when using the APP. As a result, you may be charged by the Mobile Provider for access to network connection services for the duration of the connection while accessing the APP or any such third party charges as may arise. You accept responsibility for any such charges that arise.</p>
                              <p style={{ lineHeight: 2 }}>11.2	If you are not the bill payer for the mobile telephone or handheld device being used to access the APP, you will be assumed to have received permission from the bill payer for using the APP.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>12	Availability</p>
                              <p style={{ lineHeight: 2 }}>12.1	This APP is available to handheld mobile devices running Apple iOS operating system. We will use reasonable efforts to make the APP available at all times. However you acknowledge the APP is provided over the internet and mobile networks and so the quality and availability of the APP may be affected by factors outside our reasonable control.</p>
                              <p style={{ lineHeight: 2 }}>12.2	We do not accept any responsibility whatsoever for unavailability of the APP, or any difficulty or inability to download or access content or any other communication system failure which may result in the APP being unavailable.</p>
                              <p style={{ lineHeight: 2 }}>12.3	We will not be responsible for any support or maintenance for the APP.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>13	System requirements</p>
                              <p style={{ lineHeight: 2 }}>13.1	In order to use the APP, you are required to have a compatible mobile telephone or handheld device, internet access, and the necessary minimum specifications ("Software Requirements").</p>
                              <p style={{ lineHeight: 2 }}>13.2	The version of the APP software may be upgraded from time to time to add support for new functions and services.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>14	Disclaimer</p>
                              <p style={{ lineHeight: 2 }}>14.1	We make no representation or warranty as to the reliability, timeliness, quality, suitability, availability, accuracy or completeness of the service or any content. We make no representation or warranty that (a) the use of the service will be secure, timely, uninterrupted or error-free or operate in combination with any other hardware, software, system or data, (b) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your requirements or expectations, (c) the service will meet your requirements or expectations, (d) the service or the server(s) that make the service available are free of viruses or other harmful components.  The service and all content is provided to you strictly on an "as is" basis, (e) any stored data will be accurate or reliable, or (f) errors or defects will be corrected. </p>
                              <p style={{ lineHeight: 2 }}>14.2	All conditions, representations and warranties, whether express, implied, statutory or otherwise, including, without limitation, any implied warranty of merchantability, quality, fitness for a particular purpose or non-infringement of third party rights, are hereby disclaimed to the maximum extent permitted by applicable law by us.</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>15	Cookies and other policies</p>
                              <p style={{ lineHeight: 2 }}>15.1	By accepting these Terms, you consent and agree to the use of all cookies and related tracking devices on our site/APP.  Details of such devices as applicable to our site/APP are detailed in the "Cookies Policy" section on our site/APP.  You confirm that you shall make all Users aware that you have consented to this as the party paying for the Services and that this consent will therefore be deemed to apply to their own use of the Services as this will be in the course of their employment or consultancy.  </p>
                              <p style={{ lineHeight: 2 }}>15.2	You also acknowledge that use of our site will be subject to such other policies as are specified on our site/APP from time to time.  </p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}></p>
                              <p style={{ lineHeight: 2 }}>16	Notices</p>
                              <p style={{ lineHeight: 2 }}>16.1	We may give notice by means of a general notice on the Service, electronic mail to your e-mail address on record in our account information, or by written communication sent by first class mail or pre-paid post to your address on record in our account information. </p>
                              <p style={{ lineHeight: 2 }}>16.2	You may give notice to us (such notice shall be deemed given when received by us) at any time by written communication sent by first class mail or pre-paid post to our registered office.</p>
                              <p style={{ lineHeight: 2 }}>16.3	Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if sent by email).</p>
                              <p style={{ lineHeight: 2, fontWeight: 'bold', color: 'black' }}>17	Governing law and jurisdiction</p>
                              <p style={{ lineHeight: 2 }}>These Terms shall be governed by and construed in accordance with laws of England and Wales. If any provision of these Terms shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions. By accepting these Terms you submit to the non-exclusive jurisdiction of the courts of England and Wales.</p>
                              </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withRouter(TermsAndConditions);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FooterOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      divName: 'RTL',
      colorPick: false,
    };
  }

  componentDidMount() {
    /*=====================
         Pre loader
         ==========================*/
    setTimeout(function() {
      document.querySelector('.holder').style = 'display: none';
    }, 4000);
  }

  render() {
    return (
      <footer className="footer-light">
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-lg-4 col-md-6">
                <div className="footer-contant">
                  <p>
                    At Gyphto it is our mission to make it easy for friends,
                    family and colleagues to buy gifts for each other. Gyphto is
                    a group gifting platform that streamlines and simplifies the
                    group collection and gift giving process.
                  </p>
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/Gyphto/"
                          target="_blank"
                        >
                          <i
                            className="fa fa-facebook"
                            aria-hidden="true"
                            style={{ color: '#4514A2' }}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/GyphtoHQ" target="_blank">
                          <i
                            className="fa fa-twitter"
                            aria-hidden="true"
                            style={{ color: '#4514A2' }}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/gyphto_groupgifts/"
                          target="_blank"
                        >
                          <i
                            className="fa fa-instagram"
                            aria-hidden="true"
                            style={{ color: '#4514A2' }}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/gyphto/"
                          target="_blank"
                        >
                          <i
                            className="fa fa-linkedin"
                            aria-hidden="true"
                            style={{ color: '#4514A2' }}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col offset-xl-1">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>Quick Links</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/all-collections`}>
                          My Collections
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/create`}>
                          Create a Collection
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/account`}>
                          My Account
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4></h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <a href="#"></a>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                        >
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <a href="#">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sub-footer ">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="footer-end">
                  <p>
                    <i className="fa fa-copyright" aria-hidden="true"></i>2021
                    Gyphto
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="payment-card-bottom">
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/american-express.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/discover.png`}
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterOne;

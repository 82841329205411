import React, { Component } from 'react';
import { Elements, CardElement, injectStripe } from 'react-stripe-elements';
import cookie from 'react-cookie';
import axios from 'axios';

import MoonLoader from 'react-spinners/MoonLoader';

const { REACT_APP_API_URL } = process.env;

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pledgeAmount: 0.0,
      stripeFee: 0.0,
      email: '',
      stripeTransactionPercent: 0.014,
      gyphtoTransactionPercent: 0.04,
      stripeTransactionFee: 0.2,
      paymentProcessing: false,
      paymentComplete: false,
    };
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    const { collectionId, getData } = this.props;
    const {
      pledgeAmount,
      email,
      stripeTransactionFee,
      stripeTransactionPercent,
    } = this.state;
    this.props.stripe.createToken({ name: 'Name' }).then(({ token }) => {
      if (token) {
        this.setState({ paymentProcessing: true });

        // const totalTransaction = (parseFloat(pledgeAmount))/(1 - gyphtoTransactionPercent);
        const totalTransaction =
          (parseFloat(pledgeAmount) + stripeTransactionFee) /
          (1 - stripeTransactionPercent);
        // const totalTransaction = pledgeAmount;
        axios
          .post(`${REACT_APP_API_URL}/api/auth/collection/payment`, {
            email: email,
            card: token,
            collectionId,
            pledgeAmount,
            totalTransaction,
          })
          .then(response => {
            getData();
            this.setState({ paymentComplete: true, paymentProcessing: false });
          })
          .catch(error => {
            getData();
            this.setState({ paymentComplete: false, paymentProcessing: false });
            console.log(error);
          });
      } else {
        getData();

        this.setState({ paymentComplete: false, paymentProcessing: false });
      }
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  renderForm() {
    const collection = this.props.collection;
    const { pledgeAmount, email, validCard } = this.state;
    const regexEmail = this.validateEmail(email);
    const isValid = pledgeAmount && regexEmail && validCard;
    const { stripeTransactionFee, stripeTransactionPercent } = this.state;
    let { stripeFee } = this.state;
    stripeFee =
      (parseFloat(pledgeAmount) + stripeTransactionFee) /
      (1 - stripeTransactionPercent);
    // const gyphtoFee = (parseFloat(pledgeAmount))/(1 - gyphtoTransactionPercent);
    const serviceCharge = (stripeFee - pledgeAmount).toFixed(2);
    return (
      <>
        <h4
          style={{
            fontSize: '1.3em',
            paddingBottom: 20,
          }}
        >
          Contribute to {collection.collectionName}
        </h4>
        <form className="theme-form">
          <div className="form-row">
            <div className="col-5">
              <label htmlFor="review">{'Contribution (£)'}</label>
              <input
                style={{
                  backgroundColor: '#EAE3F7',
                }}
                type="number"
                className="form-control"
                placeholder={
                  collection.suggestedAmount
                    ? `e.g. £${collection.suggestedAmount} (Suggested)`
                    : 'e.g. £10'
                }
                onChange={e => this.setState({ pledgeAmount: e.target.value })}
              />
            </div>
            <br />
            <div className="col-7">
              <label htmlFor="review">Email</label>
              <input
                style={{
                  backgroundColor: '#EAE3F7',
                }}
                type="email"
                className="form-control"
                placeholder="Enter email"
                onChange={e => this.setState({ email: e.target.value })}
              />
            </div>
          </div>
        </form>
        <br />
        <label htmlFor="review">Card Details</label>
        <div style={{ backgroundColor: '#EAE3F7', padding: '10px' }}>
          <CardElement
            onChange={e => this.setState({ validCard: e.complete })}
            style={{
              base: {
                iconColor: '#534B64',
                color: '#2F2F2F',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '18px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                  color: '#534B64',
                },
                '::placeholder': {
                  color: '#534B64',
                },
              },
            }}
          />
        </div>
        <br />
        <button
          onClick={this.submit}
          className="btn btn-solid"
          style={{
            background: isValid ? '#3DACFE' : '#DFDFDF',
            borderColor: isValid ? '#3DACFE' : '#DFDFDF',
            width: '100%',
            display: 'block',
          }}
        >
          <span style={{ color: isValid ? 'white' : '#A9A9A9' }}>
            Pledge £{pledgeAmount ? parseFloat(pledgeAmount).toFixed(2) : 0.0}{' '}
            {pledgeAmount > 0 ? `+ £${serviceCharge} Service Charge` : ''}
          </span>
        </button>

        <img
          src={`${process.env.PUBLIC_URL}/assets/images/stripe/secure-payment.png`}
          style={{
            marginTop: 30,
          }}
          className="img-fluid"
          alt=""
          resizeMode="contain"
        />
      </>
    );
  }

  renderLoading() {
    return (
      <div
        style={{
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <MoonLoader
          size={60}
          color={'#9B7DD2'}
          loading={this.state.paymentProcessing}
        />
        <h4 style={{ marginTop: 40, color: '#671FB0' }}>
          Processing Payment...
        </h4>
      </div>
    );
  }

  renderComplete() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h3
          style={{
            color: '#4F4F4F',
            fontSize: '1.5em',
            textAlign: 'center',
            marginTop: 10,
          }}
        >
          Thank you for pledging!
        </h3>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/tick.png`}
          style={{
            width: '180px',
            height: 'auto',
            marginTop: 10,
          }}
          className="img-fluid"
          alt=""
          resizeMode="contain"
        />
        <p
          style={{
            color: '#4F4F4F',
            fontSize: '1.2em',
            textAlign: 'center',
            marginTop: 10,
          }}
        >
          You will be notified by email when the collection is complete or if
          the collection host makes any changes.
        </p>
      </div>
    );
  }

  render() {
    const { paymentComplete, paymentProcessing } = this.state;
    return (
      <div
        className="checkout"
        style={{
          paddingTop: 20,
        }}
      >
        {!paymentProcessing && !paymentComplete && this.renderForm()}
        {paymentProcessing && this.renderLoading()}
        {!paymentProcessing && paymentComplete && this.renderComplete()}
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);

import React, {Component} from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";

class NavigateNextIcon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openFilter: false
        }
    }


    render (){
      const { type, noOfPages, currentPage } = this.props;
      let disabled = false;
      if (type === 'left' && currentPage === 1) {
        disabled = true;
      } else if (type === "right" && currentPage === noOfPages) {
        disabled = true;
      }
      return (
        <span
          style={{
            display: "block",
            height: "35px",
            width: "35px",
            lineHeight: "35px",
            borderRadius: "35px",
            borderWidth: 3,
            backgroundColor: disabled ? "#BFBFBF" : "#AB95D2",
            borderColor: disabled ? "#BFBFBF" : "#AB95D2",
            textAlign: "center",
            fontSize: "1.2em",
            position: "absolute",
          }}
        >
          <FontAwesomeIcon
            icon={type === "left" ? faChevronLeft : faChevronRight}
            color={disabled ? "#DEDEDE" : "#EAE3F6"}
          />
        </span>
      );
    }
}


const mapStateToProps = state => ({
    filters: state.filters
})

export default connect(mapStateToProps)(NavigateNextIcon);

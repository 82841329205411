import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { loginUser } from '../../actions/index';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

const form = reduxForm({
  form: 'login',
});
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#E33E7F',
    },
  },
});
const textFieldStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  color: 'white',
  borderBottomColor: 'white',
  backgroundColor: 'rgba(148,116,208, 0.4)',
  paddingLeft: 10,
  height: 35,
  fontFamily: 'Quicksand',
};

const textFieldInputStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  color: 'white',
  borderBottomColor: 'white',
  paddingLeft: 10,
  fontFamily: 'Quicksand',
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      divName: 'RTL',
      colorPick: false,
      email: null,
      password: null,
      error: null,
    };
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  /*=====================
   Tap on Top
   ==========================*/

  ChangeRtl(divName) {
    if (divName === 'RTL') {
      document.body.classList.add('rtl');
      this.setState({ divName: 'LTR' });
    } else {
      document.body.classList.remove('rtl');
      this.setState({ divName: 'RTL' });
    }
  }
  componentDidMount() {
    /*=====================
       Pre loader
       ==========================*/
    setTimeout(function() {
      document.querySelector('.holder').style = 'display: none';
    }, 3700);
  }

  showHideColor() {
    this.setState({ colorPick: !this.state.colorPick });
  }

  changeColor(color) {
    document
      .getElementById('color')
      .setAttribute(
        'href',
        `${process.env.PUBLIC_URL}/assets/css/` + color + `.css`,
      );
  }

  handleFormSubmit(formProps) {
    const { email, password } = this.state;
    formProps.email = email;
    formProps.password = password;
    this.props.loginUser(formProps, this.props.history);
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  renderErrorMessage() {
    const { errorMessage } = this.props;

    if (errorMessage) {
      return (
        <React.Fragment>
          <div style={{ marginTop: 10, color: '#edcf2f', textAlign: 'center' }}>
            <i
              className="fa fa-exclamation-triangle"
              style={{ marginRight: 15 }}
            />{' '}
            You have entered invalid details
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div style={{ marginTop: 10, color: '#edcf2f', textAlign: 'center' }}>
          <i
            className="fa fa-exclamation-triangle"
            style={{ display: 'none' }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <MuiThemeProvider muiTheme={theme}>
        <div>
          <div
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/splash_optimised.jpg)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div
              style={{
                backgroundImage:
                  'linear-gradient(rgba(110,71,186, 0.85), rgba(110,71,186, 0.85))',
                backgroundSize: 'cover',
                height: '100%',
                width: '100%',
                paddingTop: '5vh',
              }}
            >
              {/*Login section*/}
              <section className="login-page section-b-space">
                <div className="container">
                  <div
                    className="row"
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className="col-lg-6">
                      <div style={{ textAlign: 'center' }}>
                        <img
                          width="180vh"
                          src={`${process.env.PUBLIC_URL}/assets/images/logo-g.png`}
                        />
                        <h5
                          style={{
                            fontFamily: 'Quicksand',
                            color: 'white',
                          }}
                        >
                          Group Collections & Gifting
                        </h5>
                      </div>
                      {this.renderErrorMessage()}
                      <div
                        style={{
                          marginLeft: 20,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      >
                        <form className="theme-form">
                          <div
                            className="form-group"
                            style={{ marginBottom: 20 }}
                          >
                            <TextField
                              fullWidth
                              label="Email"
                              InputLabelProps={{
                                style: textFieldInputStyle,
                              }}
                              InputProps={{
                                style: textFieldStyle,
                              }}
                              onChange={event => this.handleEmail(event)}
                            />
                          </div>

                          <div
                            className="form-group"
                            style={{ marginBottom: 30 }}
                          >
                            <TextField
                              type="password"
                              fullWidth
                              label="Password"
                              InputLabelProps={{
                                style: textFieldInputStyle,
                              }}
                              InputProps={{
                                style: textFieldStyle,
                              }}
                              onChange={event => this.handlePassword(event)}
                            />
                          </div>

                          <div></div>
                        </form>
                        <button
                          onClick={this.handleFormSubmit.bind(this)}
                          className="btn btn-solid"
                          style={{
                            fontFamily: 'Quicksand',
                            background: '#9471d2',
                            borderColor: '#9471d2',
                            width: '100%',
                            display: 'block',
                          }}
                        >
                          Log in
                        </button>
                        <div
                          style={{
                            fontFamily: 'Quicksand',
                            textAlign: 'right',
                            color: 'white',
                            marginTop: 15,
                            marginBottom: 35,
                          }}
                        >
                          <a
                            href="/pages/forget-password"
                            style={{ color: 'white' }}
                          >
                            Forgot password?
                          </a>
                        </div>
                        <a
                          href="#"
                          // href="/pages/register"
                          className="btn btn-solid"
                          style={{
                            fontFamily: 'Quicksand',
                            background: 'rgb(148, 113, 210, 0)',
                            borderColor: '#9471d2',
                            color: '#9471d2',
                            width: '100%',
                            display: 'block',
                            marginBottom: '400px',
                          }}
                        >
                          Create an account
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.authentication.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps, { loginUser })(form(Login)));

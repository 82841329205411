import {
  AUTH_USER,
  UNAUTH_USER,
  ERROR_MESSAGE
  } from "../constants/ActionTypes";

export default function authenticationReducer(state = {
    authenticated: false,
    firstName: '',
    lastName: '',
    email: '',
    userId: '',
    errorMessage: null,
}, action) {
    switch (action.type) {
      case AUTH_USER:
      const payload = action.payload;
       return {
          ...state, error: '', message: '', authenticated: true, userId: payload && payload.userId ? payload.userId : state.userId, firstName: payload && payload.firstName ? payload.firstName : state.firstName , lastName: payload && payload.lastName ? payload.lastName : state.lastName, email: payload && payload.email ? payload.email : state.email
        };
     
      case UNAUTH_USER:
        return {
          ...state, authenticated: false, userId: null
        };
      case ERROR_MESSAGE:
      const errorMessage = action.payload;
        return {
          ...state, errorMessage
        };
      default:
    }
    return state;
}

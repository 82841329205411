import React, { Component } from 'react';
import './dropdown-style.css';
import { withRouter } from 'react-router-dom';
import { createCollection } from '../../actions/index';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import 'url-search-params-polyfill';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const form = reduxForm({
  form: 'collection',
});
class ConnectSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionType: 'Birthday',
    };
  }

  componentWillMount() {
    var search = new URLSearchParams(window.location.search);
    this.getStripeDetails(search.get('code'));
  }

  getStripeDetails(code) {
    const email = localStorage.getItem('userId');
    fetch('https://connect.stripe.com/oauth/token', {
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      method: 'post',
      body: JSON.stringify({
        client_secret: 'sk_test_rJPFP0ItAmmbLetgxTJkdS7H',
        code: code,
        grant_type: 'authorization_code',
      }),
    })
      .then(response => response.json())
      .then(function(data) {
        fetch(`${REACT_APP_API_URL}/api/auth/users/edit/${email}`, {
          crossDomain: true,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          method: 'put',
          body: JSON.stringify({
            stripeId: data.stripe_user_id,
          }),
        })
          .then(response => response.json())
          .then(function(res) {
            localStorage.setItem('stripeId', data.stripe_user_id);
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  onSelect(props) {
    this.setState({ collectionType: props.value });
  }

  redirect() {
    const history = this.props.history;

    history.push('/');
  }

  render() {
    return (
      <div>
        <div
          className="breadcrumb-section"
          style={{ backgroundColor: '#aa95d2' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff' }}>{'Create a Collection'}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className=" contact-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <h3 style={{ fontSize: 30 }}>Successfully linked Stripe</h3>
                  <br />

                  <p style={{ fontSize: 18, lineHeight: 1.5 }}>
                    Your account is now connected with your bank. You can now
                    receive collection funds for the gift.
                  </p>
                  <p style={{ fontSize: 18, lineHeight: 1.5 }}>
                    You are ready to ask your friends, family and work
                    colleagues to make a contribution
                  </p>

                  <br />
                </div>
                <div style={{ width: '100%' }}>
                  <button
                    onClick={() => this.redirect()}
                    className="btn btn-solid"
                    style={{
                      background: '#3DACFF',
                      borderColor: '#3DACFF',
                      height: 60,
                      width: '100%',
                      display: 'block',
                    }}
                  >
                    Continue
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
  };
}

export default withRouter(
  connect(mapStateToProps, { createCollection })(form(ConnectSuccess)),
);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

import { forgotPassword } from '../../actions/index';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
const form = reduxForm({
  form: 'forgot',
});
const textFieldStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  color: 'white',
  borderBottomColor: 'white',
  backgroundColor: 'rgba(148,116,208, 0.4)',
  paddingLeft: 10,
  fontFamily: 'Quicksand',
};

const textFieldInputStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  color: 'white',
  borderBottomColor: 'white',
  paddingLeft: 10,
  fontFamily: 'Quicksand',
};

class ForgetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      divName: 'RTL',
      colorPick: false,
      email: null,
      error: null,
    };
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  /*=====================
   Tap on Top
   ==========================*/

  ChangeRtl(divName) {
    if (divName === 'RTL') {
      document.body.classList.add('rtl');
      this.setState({ divName: 'LTR' });
    } else {
      document.body.classList.remove('rtl');
      this.setState({ divName: 'RTL' });
    }
  }
  componentDidMount() {
    /*=====================
       Pre loader
       ==========================*/
    setTimeout(function() {
      document.querySelector('.holder').style = 'display: none';
    }, 4000);
  }

  showHideColor() {
    this.setState({ colorPick: !this.state.colorPick });
  }

  changeColor(color) {
    document
      .getElementById('color')
      .setAttribute(
        'href',
        `${process.env.PUBLIC_URL}/assets/css/` + color + `.css`,
      );
  }

  handleFormSubmit(formProps) {
    const { email } = this.state;
    if (!email) {
      this.setState({ error: 'Enter your email' });
    } else {
      formProps.email = email;
    }
    this.props.forgotPassword(formProps, this.props.history);
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }

  renderErrorMessage() {
    const { error } = this.state;

    if (error) {
      return (
        <React.Fragment>
          <div style={{ marginTop: 10, color: '#edcf2f', textAlign: 'center' }}>
            <i
              className="fa fa-exclamation-triangle"
              style={{ marginRight: 10 }}
            />{' '}
            {this.state.error}
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div style={{ marginTop: 10, color: '#edcf2f', textAlign: 'center' }}>
          <i
            className="fa fa-exclamation-triangle"
            style={{ display: 'none' }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { handleSubmit } = this.props;

    let color_style = this.state.colorPick
      ? { right: '0px' }
      : { right: '-190px' };
    let tap_to_top = { display: 'none' };

    return (
      <div>
        <div
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/splash.jpg)`,
            backgroundSize: 'cover',
            height: '100vh',
            backgroundPosition: 'center',
            backgroundRepeat: 'repeat',
            // paddingTop: '5vh'
          }}
        >
          <div
            style={{
              backgroundImage:
                'linear-gradient(rgba(110,71,186, 0.85), rgba(110,71,186, 0.85))',
              height: '100%',
              width: '100%',
              paddingTop: '5vh',
            }}
          >
            {/*Login section*/}
            <section className="login-page section-b-space">
              <div className="container">
                <div
                  className="row"
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <div className="col-lg-6">
                    <div style={{ marginLeft: 20 }}>
                      <img
                        width="20%"
                        src={`${process.env.PUBLIC_URL}/assets/images/logo-g.png`}
                      />
                      <span
                        style={{
                          marginLeft: 20,
                          fontFamily: 'Quicksand',
                          color: 'white',
                          fontSize: '20px',
                          fontWeight: 'normal',
                          fontStyle: 'normal',
                        }}
                      >
                        Forgot Password
                      </span>
                    </div>
                    {this.renderErrorMessage()}
                    <div
                      style={{
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 10,
                      }}
                    >
                      <form className="theme-form">
                        <div
                          className="form-group"
                          style={{ marginBottom: 20 }}
                        >
                          <TextField
                            fullWidth
                            label="Email"
                            InputLabelProps={{
                              style: textFieldInputStyle,
                            }}
                            InputProps={{
                              style: textFieldStyle,
                            }}
                            onChange={event => this.handleEmail(event)}
                          />
                        </div>
                      </form>
                      <button
                        onClick={this.handleFormSubmit.bind(this)}
                        className="btn btn-solid"
                        style={{
                          fontFamily: 'Quicksand',
                          background: '#9471d2',
                          borderColor: '#9471d2',
                          width: '100%',
                          display: 'block',
                        }}
                      >
                        Reset password
                      </button>
                      <br />
                      <a
                        href="/pages/login"
                        className="btn btn-solid"
                        style={{
                          fontFamily: 'Quicksand',
                          background: 'rgb(148, 113, 210, 0)',
                          borderColor: '#9471d2',
                          color: '#9471d2',
                          width: '100%',
                          display: 'block',
                        }}
                      >
                        Log in
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.authentication.errorMessage,
  };
}

export default withRouter(
  connect(mapStateToProps, { forgotPassword })(form(ForgetPassword)),
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-input-range/lib/css/index.css';

import { getBrands, getColors, getMinMaxPrice } from '../../../services';
import { filterBrand, filterColor, filterPrice } from '../../../actions';

class SortDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openFilter: false,
      checked: 0,
    };
  }

  render() {
    const sortOptions = [
      { label: 'Relevance', value: 'Relevance' },
      { label: 'Average Customer Reviews', value: 'AvgCustomerReviews' },
      { label: 'Price: Low - High', value: 'Price:LowToHigh' },
      { label: 'Price: High - Low', value: 'Price:HighToLow' },
      { label: 'Newest Arrivals', value: 'NewestArrivals' },
      { label: 'Featured', value: 'Featured' },
    ];
    const { closeFilter, sortBy, onSortBy } = this.props;
    return (
      <div className="collection-filter-block">
        <div
          className="collection-brand-filter"
          style={{
            marginTop: 50,
            marginBottom: 30,
          }}
        >
          {sortOptions.map((option, i) => {
            return (
              <div
                className="custom-control custom-checkbox collection-filter-checkbox"
                onClick={() => {
                  this.setState({ checked: i });
                  onSortBy(option.value);
                }}
              >
                <label key={option.value} style={{ marginRight: 20 }}>
                  <input
                    type="radio"
                    checked={sortBy === option.value ? true : false}
                    key={option.value}
                    onChange={e => onSortBy(e.target.value)}
                    value={option.value}
                  />
                  {option[this.props.name]}
                </label>
                <label htmlFor={option.label}>{option.label}</label>
              </div>
            );
          })}

          <div
            style={{
              height: 60,
            }}
          >
            <button
              className="btn btn-solid"
              onClick={() => {
                closeFilter();
              }}
              style={{
                background: '#3DACFF',
                borderColor: '#3DACFF',
                width: '120px',
                float: 'right',
                margin: 10,
                padding: '7px 15px',
              }}
            >
              <span style={{ color: 'white' }}>CLOSE</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brands: getBrands(state.data.products),
  colors: getColors(state.data.products),
  prices: getMinMaxPrice(state.data.products),
  filters: state.filters,
});

export default connect(mapStateToProps, {
  filterBrand,
  filterColor,
  filterPrice,
})(SortDropdown);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { SlideToggle } from 'react-slide-toggle';

import { getBrands, getColors, getMinMaxPrice } from '../../../services';
import { filterBrand, filterColor, filterPrice } from '../../../actions';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openFilter: false,
    };
  }

  clickBrandHendle(event, brands) {
    var index = brands.indexOf(event.target.value);
    if (event.target.checked) brands.push(event.target.value);
    else brands.splice(index, 1);

    this.props.filterBrand(brands);
  }

  colorHandle(event, color) {
    var elems = document.querySelectorAll('.color-selector ul li');
    [].forEach.call(elems, function(el) {
      el.classList.remove('active');
    });
    event.target.classList.add('active');
    this.props.filterColor(color);
  }

  render() {
    const { getData } = this.props;
    return (
      <div className="collection-filter-block">
        <div
          className="collection-brand-filter"
          style={{
            marginTop: 50,
            marginBottom: 30,
          }}
        >
          <div className="custom-control custom-checkbox collection-filter-checkbox">
            <InputRange
              maxValue={this.props.collectionValue * 5}
              minValue={0}
              value={{
                min: this.props.minPrice,
                max: this.props.maxPrice,
              }}
              onChange={(min, max) => {
                this.props.onChangePriceFilter(min, max);
              }}
            />
          </div>
        </div>
        <div
          style={{
            height: 60,
          }}
        >
          <button
            className="btn btn-solid"
            onClick={() => getData()}
            style={{
              background: '#3DACFF',
              borderColor: '#3DACFF',
              width: '120px',
              float: 'right',
              margin: 10,
              padding: '7px 15px',
            }}
          >
            <span style={{ color: 'white' }}>APPLY</span>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brands: getBrands(state.data.products),
  colors: getColors(state.data.products),
  prices: getMinMaxPrice(state.data.products),
  filters: state.filters,
});

export default connect(mapStateToProps, {
  filterBrand,
  filterColor,
  filterPrice,
})(Filter);

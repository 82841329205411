import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import { Link } from 'react-router-dom';

class DetailsTopTabs extends Component {
  render() {
    const { item } = this.props;
    const features = item && item.ItemInfo.Features.DisplayValues;
    const manufactureInfo = item && item.ItemInfo.ManufactureInfo;
    const productInfo = item && item.ItemInfo.ProductInfo;
    const itemDimensions = productInfo && productInfo.ItemDimensions;
    const height =
      (itemDimensions &&
        itemDimensions.Height &&
        itemDimensions.Height.DisplayValue) ||
      0;
    const length =
      (itemDimensions &&
        itemDimensions.Length &&
        itemDimensions.Length.DisplayValue) ||
      0;
    const width =
      (itemDimensions &&
        itemDimensions.Width &&
        itemDimensions.Width.DisplayValue) ||
      0;
    const weight =
      (itemDimensions &&
        itemDimensions.Weight &&
        itemDimensions.Weight.DisplayValue) ||
      0;
    const itemPartNumber =
      manufactureInfo && manufactureInfo.ItemPartNumber.DisplayValue;
    const model =
      manufactureInfo &&
      manufactureInfo.Model &&
      manufactureInfo.Model.DisplayValue;
    const primeEligable =
      item && item.Offers
        ? item.Offers.Listings[0].DeliveryInfo.IsPrimeEligible
        : false;
    const condition =
      item && item.Offers ? item.Offers.Listings[0].Condition.Value : false;
    return (
      <section className="tab-product m-0">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <Tabs className="tab-content nav-material">
              <TabList className="nav nav-tabs nav-material">
                <Tab className="nav-item">
                  <span className="nav-link active">
                    <i className="icofont icofont-ui-home"></i>Description
                  </span>
                  <div className="material-border"></div>
                </Tab>
                <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-man-in-glasses"></i>
                    Details
                  </span>
                  <div className="material-border"></div>
                </Tab>
              </TabList>
              <TabPanel
                className="tab-pane fade mt-4 show active"
                style={{
                  paddingBottom: 40,
                }}
              >
                {features.map(feature => (
                  <p className="mt-4 p-0">{feature}</p>
                ))}
              </TabPanel>

              <TabPanel>
                <table className="table table-striped mb-0">
                  <tbody>
                    <tr>
                      <th>Condition</th>
                      <td>{condition}</td>
                    </tr>
                    <tr>
                      <th>Prime Eligable</th>
                      <td>{primeEligable ? 'Yes' : 'No'}</td>
                    </tr>
                    {model && (
                      <tr>
                        <th>Model</th>
                        <td>{model}</td>
                      </tr>
                    )}
                    {itemDimensions && (
                      <>
                        <tr>
                          <th>Dimensions</th>
                          <td>
                            {height.toFixed(2)} x {width.toFixed(2)} x{' '}
                            {length.toFixed(2)}{' '}
                            {(itemDimensions &&
                              itemDimensions.Width &&
                              itemDimensions.Width.Unit) ||
                              ''}
                          </td>
                        </tr>
                        <tr>
                          <th>Weight</th>
                          <td>
                            {weight.toFixed(2)}{' '}
                            {(itemDimensions &&
                              itemDimensions.Weight &&
                              itemDimensions.Weight.Unit) ||
                              ''}
                          </td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <th>Part Number</th>
                      <td>{itemPartNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </TabPanel>
              <TabPanel>
                <div className="mt-4 text-center">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      src="https://www.youtube.com/embed/BUWzX78Ye_8"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <form className="theme-form mt-4">
                  <div className="form-row">
                    <div className="col-md-12 ">
                      <div className="media m-0">
                        <label>Rating</label>
                        <div className="media-body ml-3">
                          <div className="rating three-star">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your name"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">Review Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="review"
                        placeholder="Enter your Review Subjects"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">Review Title</label>
                      <textarea
                        className="form-control"
                        placeholder="Wrire Your Testimonial Here"
                        id="exampleFormControlTextarea1"
                        rows="6"
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-solid" type="submit">
                        Submit YOur Review
                      </button>
                    </div>
                  </div>
                </form>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

export default DetailsTopTabs;

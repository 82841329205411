import React, { Component } from 'react';
import { BitlyClient } from 'bitly';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import cookie from 'react-cookie';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from '../stripe/CheckoutForm';
import Process from '../../components/common/process';
import 'react-toastify/dist/ReactToastify.min.css';
import { SlideToggle } from 'react-slide-toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import MoonLoader from 'react-spinners/MoonLoader';
import {
  faShoppingCart,
  faPlus,
  faShareAlt,
  faPiggyBank,
  faGiftCard,
  faGifts,
  faArrowAltRight,
  faSearch,
  faMoneyCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { faEdit, faGift } from '@fortawesome/pro-light-svg-icons';
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
} from 'react-share';

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  TwitterIcon,
} from 'react-share';
const bitly = new BitlyClient('92d6428f3e616e0498ea831dbcf8a39a04618b34', {});
const productBox = {
  borderBottom: '3px solid #aa96d0',
  float: 'left',
  margin: '10px 0px 10px 0px',
  position: 'relative',
  width: '100%',
  padding: '4px',
  fontFamily: 'Helvetica',
  display: 'flex',
};

const productTitle = {
  margin: '2px 3px 0 2px',
  minWidth: '40px',
  fontSize: '14px',
  lineHeight: '15px',
  color: '#000',
  position: 'relative',
  textAlign: 'center',
  overflow: 'hidden',
  height: '45px',
  paddingLeft: '10px',
  paddingRight: '10px',
  alignSelf: 'center',
  display: 'flex',
  flex: 3,
};

const productPrice = {
  textAlign: 'center',
  color: '#6E47BA',
  fontWeight: 'bold',
  alignSelf: 'flex-end',
  display: 'flex',
  fontSize: '16px',
};

const deleteIconContainer = {
  textAlign: 'center',
  color: '#6E47BA',
  fontWeight: 'bold',
  // display: "flex",
  fontSize: '16px',
  float: 'right',
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const styles = {
  numberCircle: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    padding: '10px',
    background: '#D3C3F7',
    border: '2px solid #666',
    color: '#666',
    textAlign: 'center',
    font: '24px Arial, sans-serif',
    display: 'inline-block',
    marginLeft: 10,
    marginRight: 20,
  },
};

class Collection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionData: [],
      buttonName: '',
      collectionUserId: '',
      totalPrice: '0.00',
      itemData: [],
      purchaseURL: '#',
      shortenedUrl: '',
      checkoutAvailable: true,
      showStripeConnect: false,
      completed: false,
    };
    this.cartItems = React.createRef();
  }

  async componentDidMount() {
    this.getCollections();
    await this.getURL();
  }

  getCollections() {
    const { match = {} } = this.props;
    const id = match.params.id;
    axios
      .get(`${REACT_APP_API_URL}/api/auth/collection/single/${id}`, {
        headers: { Authorization: cookie.load('token') },
      })
      .then(response => {
        this.setState({
          collectionData: response.data,
          collectionUserId: response.data.userId,
          completed: response.data.completed,
        });
        if (response.data.userId === localStorage.getItem('userId')) {
          this.setState({ buttonName: true });
        } else {
          this.setState({ buttonName: false });
        }
        this.getCart(response.data.cartId, response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
  getCart(cartId, collectionData) {
    axios
      .get(`${REACT_APP_API_URL}/api/auth/cart/single/${cartId}`, {
        headers: { Authorization: cookie.load('token') },
      })
      .then(response => {
        console.log(response);
        if (
          // response.data.items.length === 0 ||
          // !response.data.items.length ||
          !collectionData.customers ||
          !collectionData.customers.length ||
          collectionData.customers.length === 0 ||
          !collectionData.stripeId
        ) {
          this.setState({
            checkoutAvailable: false,
          });
        }
        this.setState({
          cartData: response.data,
        });
        this.getProducts();
      })
      .catch(error => {
        console.log(error);
      });
  }

  getProducts() {
    const { cartData } = this.state;
    axios
      .post(`${REACT_APP_API_URL}/api/getProducts/`, {
        items: cartData.items,
      })
      .then(response => {
        this.setState({
          cartProducts: response.data.ItemsResult.Items,
        });
        let totalPrice = 0;
        response.data.ItemsResult.Items.map(item => {
          const price = item.Offers
            ? item.Offers.Listings[0].Price.Amount
            : null;
          totalPrice = price ? totalPrice + price : totalPrice;
        });
        const collection = this.state.collectionData;
        const totalValue = collection.value && collection.value.toFixed(0);
        let cartOverPriced = false;
        if (totalPrice >= totalValue) {
          cartOverPriced = true;
        } else {
          cartOverPriced = false;
        }
        this.setState({ totalPrice: totalPrice.toFixed(2), cartOverPriced });
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getURL() {
    let shortenedUrl = '';
    const { match = {} } = this.props;
    const id = match.params.id;
    try {
      shortenedUrl = await bitly.shorten(
        `https://app-gyphto.com/collection/${id}`,
      );
      this.setState({ shortenedUrl: shortenedUrl.link });
    } catch (e) {
      throw e;
    }
  }

  onChargeUsers() {
    const { match = {} } = this.props;
    const { collectionData, cartProducts } = this.state;
    const collectionId = match.params.id;
    const stripeId = collectionData.stripeId;
    let url = 'https://www.amazon.co.uk/gp/aws/cart/add.html?';
    cartProducts &&
      cartProducts.map((item, i) => {
        const asin = item.ASIN;
        const itemParams = `ASIN.${i + 1}=${asin}&Quantity.${i + 1}=1&`;
        url = url + itemParams;
      });

    axios
      .post(`${REACT_APP_API_URL}/api/auth/collection/charge`, {
        collectionId,
        stripeId,
      })
      .then(response => {
        this.setState({ completed: true });
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  renderButton(collection) {
    const submit = () => {
      const { checkoutAvailable } = this.state;
      if (checkoutAvailable) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div
                className="custom-ui"
                style={{
                  textAlign: 'center',
                  width: '500px',
                  padding: '40px',
                  background: '#6E47B9',
                  boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
                  color: '#000',
                }}
              >
                <h1
                  style={{
                    marginTop: 0,
                    fontSize: '18px',
                    color: '#fff',
                  }}
                >
                  Are you sure?
                </h1>
                <p
                  style={{
                    marginTop: 0,
                    fontSize: '16px',
                    color: '#fff',
                    padding: '10px',
                    paddingRight: '20px',
                    paddingLeft: '20px',
                  }}
                >
                  Proceeding to checkout will collect funds from each pledgers
                  account and redirect you to amazon.com
                </p>
                <button
                  style={{
                    width: '160px',
                    padding: '10px',
                    border: '1px solid #fff',
                    margin: '10px',
                    cursor: 'pointer',
                    background: 'none',
                    color: '#fff',
                    fontSize: '14px',
                  }}
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  style={{
                    width: '160px',
                    padding: '10px',
                    border: '1px solid #fff',
                    margin: '10px',
                    cursor: 'pointer',
                    background: 'none',
                    color: '#fff',
                    fontSize: '14px',
                  }}
                  onClick={() => {
                    this.onChargeUsers();
                    onClose();
                    this.getCollections();
                  }}
                >
                  Proceed
                </button>
              </div>
            );
          },
        });
      }
    };
    const { checkoutAvailable } = this.state;
    if (this.state.buttonName) {
      return (
        <div
          style={{
            marginTop: 20,
          }}
        >
          <button
            onClick={() => submit()}
            className="btn btn-solid"
            style={{
              height: 60,
              background: checkoutAvailable ? '#3DACFF' : '#929292',
              borderColor: checkoutAvailable ? '#3DACFF' : '#929292',
              width: '100%',
              display: 'block',
            }}
          >
            <div>
              <FontAwesomeIcon
                icon={faMoneyCheck}
                color={checkoutAvailable ? '#FFF' : '#C1C1C1'}
                size="30px"
              />
              <span
                style={{
                  color: checkoutAvailable ? '#FFF' : '#C1C1C1',
                  marginLeft: 15,
                }}
              >
                Collect Funds
              </span>
            </div>
          </button>
        </div>
      );
    }
  }

  renderPayment(collection) {
    if (!this.state.buttonName) {
      return (
        <div>
          <StripeProvider apiKey="pk_live_hWGxIJisVArjfuNkgyjLfKxD">
            <div className="example">
              <Elements>
                <CheckoutForm
                  options={CARD_ELEMENT_OPTIONS}
                  collection={collection}
                  collectionId={this.props.match.params.id}
                  getData={() => this.getCollections()}
                />
              </Elements>
            </div>
          </StripeProvider>
          <br />
        </div>
      );
    }
  }

  renderSingleShareButton() {
    const { collectionData } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <button
          onClick={() =>
            this.setState({
              showStripeConnect:
                collectionData.stripeId &&
                collectionData.stripeId !== 'undefined'
                  ? false
                  : true,
            })
          }
          className="btn btn-solid"
          style={{
            background: '#3DACFF',
            borderColor: '#3DACFF',
            width: '100%',
            display: 'block',
            height: 60,
            marginTop: 20,
          }}
        >
          <FontAwesomeIcon icon={faShareAlt} color={'#fff'} size="30px" />
          <span
            style={{
              marginLeft: 20,
            }}
          >
            Share With Friends
          </span>
        </button>
      </div>
    );
  }

  renderComplete() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h3
          style={{
            color: '#4F4F4F',
            fontSize: '1.5em',
            textAlign: 'center',
            marginTop: 40,
          }}
        >
          Collection Complete!
        </h3>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/tick.png`}
          style={{
            width: '180px',
            height: 'auto',
            marginTop: 10,
          }}
          className="img-fluid"
          alt=""
          resizeMode="contain"
        />
        <p
          style={{
            color: '#4F4F4F',
            fontSize: '1.2em',
            textAlign: 'center',
            marginTop: 10,
          }}
        ></p>
      </div>
    );
  }

  renderBottom(collection) {
    if (this.state.completed) {
      return this.renderComplete();
    } else {
      return (
        <>
          {collection.stripeId && collection.stripeId !== 'undefined'
            ? this.renderSocialSection()
            : this.renderSingleShareButton()}
          {collection.stripeId && collection.stripeId !== 'undefined'
            ? this.renderButton(collection)
            : null}
        </>
      );
    }
  }

  renderSocialSection() {
    const collection = this.state.collectionData;
    const { shortenedUrl } = this.state;
    return (
      <>
        <span style={{ marginTop: 20, marginLeft: 10 }}>
          <h4
            style={{
              marginBottom: 10,
              marginLeft: 20,
              fontSize: '1.3em',
            }}
          >
            Share with Friends
          </h4>
        </span>
        <div
          style={{
            borderBottom: '1px solid #dddddd',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <span style={{ paddingRight: 10 }}>
              <WhatsappShareButton
                title={`You have been invited to contribute to ${collection.collectionName} on Gyphto`}
                separator=" "
                url={shortenedUrl}
              >
                <WhatsappIcon size={40} round={false} />
              </WhatsappShareButton>
            </span>
            <span style={{ paddingRight: 10 }}>
              <LinkedinShareButton
                title={`You have been invited to contribute to ${collection.collectionName} on Gyphto`}
                summary=""
                source={'test'}
                url={`${shortenedUrl}`}
              >
                <LinkedinIcon size={40} round={false} />
              </LinkedinShareButton>
            </span>
            <span style={{ paddingRight: 10 }}>
              <TelegramShareButton
                title={`You have been invited to contribute to ${collection.collectionName} on Gyphto`}
                url={shortenedUrl}
              >
                <TelegramIcon size={40} round={false} />
              </TelegramShareButton>
            </span>
            <span style={{ paddingRight: 10 }}>
              <FacebookShareButton
                quote={`${collection.firstName} has invited you to contribute to ${collection.collectionName} on Gyphto`}
                hashtag={'gyphto'}
                url={shortenedUrl}
              >
                <FacebookIcon size={40} round={false} />
              </FacebookShareButton>
            </span>
            <span style={{ paddingRight: 10 }}>
              <FacebookMessengerShareButton
                appId="370507050654622"
                redirectUri={shortenedUrl}
                link={shortenedUrl}
              >
                <FacebookMessengerIcon size={40} round={false} />
              </FacebookMessengerShareButton>
            </span>
            <span style={{ paddingRight: 10 }}>
              <TwitterShareButton
                title={`We are collecting funds for ${collection.collectionName} on Gyphto. Follow link to pledge money towards the gift.`}
                via={'GyphtoHQ'}
                hashtags={['gyphto', 'giftgiving']}
                url={shortenedUrl}
              >
                <TwitterIcon size={40} round={false} />
              </TwitterShareButton>
            </span>
            <span style={{ paddingRight: 10 }}>
              <EmailShareButton
                subject={`You have been invited to contribute to ${collection.collectionName} on Gyphto`}
                body={`Click the link to contribute to the collection ${shortenedUrl}`}
              >
                <EmailIcon size={40} round={false} />
              </EmailShareButton>
            </span>
          </div>
          <div
            className="col-lg-6 offset-lg-3"
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={shortenedUrl}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  renderCollectionDetails() {
    const { match = {} } = this.props;
    const { checkoutAvailable, cartProducts } = this.state;
    const collection = this.state.collectionData;
    const usersPledged = collection.customers ? collection.customers.length : 0;
    const currentValue = collection.amountPledged
      ? collection.amountPledged.toFixed(2)
      : 0;
    const totalValue = collection.value && collection.value.toFixed(2);
    const progressCircleValue =
      (collection.amountPledged / collection.value) * 100 || 0;
    const id = collection.collectionId;
    return (
      <>
        <div
          style={{
            display: 'flex',
            flex: 10,
            width: '100%',
            alignItems: 'right',
            justifyContent: 'right',
            flexDirection: 'row',
            backgroundColor: '#D3C3F7',
            padding: '15px',
            paddingTop: '25px',
            marginTop: 0,
            borderRadius: 10,
          }}
        >
          <div style={{ width: '30%', padding: '10px' }}>
            <CircularProgressbar
              value={isNaN(progressCircleValue) ? 0 : progressCircleValue}
              text={`${
                isNaN(progressCircleValue) ? 0 : progressCircleValue.toFixed(0)
              }%`}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: 'round',
                textSize: '1em',
                pathTransitionDuration: 0.5,
                pathColor: `rgba(147, 113, 210, ${(collection.amountPledged /
                  collection.value) *
                  100})`,
                textColor: '#633b9e',
                trailColor: '#fff',
                backgroundColor: '#fff',
              })}
            />
          </div>
          <div style={{ flex: 5, marginLeft: 20 }}>
            <h2 style={{ color: '#2F2F2F', fontSize: '1.2em' }}>
              <span
                style={{
                  fontSize: '1.6em',
                }}
              >
                {' '}
                £{isNaN(currentValue) ? 0 : currentValue}{' '}
              </span>
              {collection.userId === localStorage.getItem('userId') && (
                <Link to={`${process.env.PUBLIC_URL}/edit-collection/${id}`}>
                  <span
                    style={{
                      fontSize: '1.2em',
                      float: 'right',
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} color={'#2F2F2F'} />
                  </span>
                </Link>
              )}
              <br />
              <span
                style={{
                  color: '#2F2F2F',
                  fontSize: '1em',
                  textTransform: 'lowercase',
                }}
              >
                contributions made
              </span>
            </h2>
            <h4
              style={{
                color: '#2F2F2F',
                textTransform: 'lowercase',
                fontSize: '1em',
              }}
            >
              out of £{totalValue} goal
            </h4>
            <br></br>
            <ul className="post-social">
              <li>
                <h4
                  style={{
                    color: '#2F2F2F',
                    textTransform: 'lowercase',
                  }}
                >
                  <span
                    style={{
                      fontSize: '1em',
                    }}
                  >
                    <FontAwesomeIcon icon={faGift} color={'#2F2F2F'} />
                  </span>
                  <span
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {`${usersPledged} contributor${
                      usersPledged !== 1 ? 's' : ''
                    }`}
                  </span>
                </h4>
              </li>
            </ul>
            <ul
              className="post-social"
              style={{
                borderBottom: 0,
                marginBottom: 0,
                color: '#2F2F2F',
              }}
            >
              {/* <li>02 January 2020</li> */}
              <li>
                Created by : <b>{collection.firstName}</b>
              </li>
            </ul>
            {/* <p>{collection.collectionDescription}</p> */}
          </div>
        </div>
        <div
          style={{
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
        >
          {!this.state.completed &&
            collection.stripeId &&
            collection.stripeId !== 'undefined' &&
            this.renderPayment(collection)}
          <div class="row">
            <div class="col-sm-4">
              <p>{collection.collectionDescription}</p>
            </div>
          </div>

          <ul
            className="post-social"
            style={{
              paddingBottom: 0,
            }}
          ></ul>
          <SlideToggle
            collapsed={localStorage.getItem('userId') ? true : false}
          >
            {({ onToggle, setCollapsibleElement }) => (
              <div
                className="collection-collapse-block"
                id="cart"
                style={{
                  borderBottom: '1px solid #dddddd',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    height: '40px',
                    width: '40px',
                    lineHeight: '40px',
                    borderRadius: '35px',
                    borderWidth: 3,
                    backgroundColor: '#3CACFD',
                    borderColor: '#3CACFD',
                    textAlign: 'center',
                    fontSize: '1.3em',
                    position: 'absolute',
                    marginTop: -10,
                  }}
                >
                  <FontAwesomeIcon icon={faShoppingCart} color={'#AFDDFD'} />
                </span>
                <h3
                  id="cart"
                  className="collapse-block-title"
                  style={{
                    borderColor: '#dddddd',
                    color: '#4F4F4F',
                    paddingBottom: 15,
                    marginRight: 20,
                    marginLeft: 60,
                  }}
                  onClick={onToggle}
                >
                  Shopping Cart (
                  {cartProducts && cartProducts.length > 0
                    ? cartProducts.length
                    : 0}
                  )
                  <span
                    style={{
                      color:
                        parseFloat(this.state.totalPrice) > collection.value
                          ? 'red'
                          : 'green',
                      float: 'right',
                      marginRight: 30,
                    }}
                  >
                    £{this.state.totalPrice}
                  </span>
                </h3>
                <div
                  className="collection-collapse-block-content"
                  ref={setCollapsibleElement}
                >
                  {cartProducts && cartProducts.length === 0 ? (
                    <div>
                      <br />
                      <br />
                      <br />
                    </div>
                  ) : null}

                  <div
                    style={{
                      flex: 10,
                      flexDirection: 'row',
                      display: 'flex',
                      marginTop: 20,
                    }}
                  >
                    {localStorage.getItem('userId') && (
                      <div style={{ flex: 10 }}>
                        <Link
                          to={`${process.env.PUBLIC_URL}/pages/search/${match.params.id}`}
                        >
                          <button
                            className="btn btn-solid"
                            style={{
                              height: 60,
                              border: '2px solid #3DACFE',
                              background: '#3DACFE',
                              borderColor: '#3DACFE',
                              borderWidth: 0,
                              width: '100%',
                              display: 'block',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={!checkoutAvailable ? faSearch : faPlus}
                              color={'#fff'}
                              size="30px"
                            />
                            <span
                              style={{
                                marginLeft: 20,
                              }}
                            >
                              {!checkoutAvailable
                                ? 'Find a gift'
                                : 'Add more items'}
                            </span>
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                  {cartProducts && (
                    <div
                      ref="cartItems"
                      style={{ borderTop: '3px solid #aa96d0' }}
                    >
                      {cartProducts &&
                        cartProducts.map((item, i) => {
                          const mediumImage = item.Images.Primary.Medium;
                          const mediumImageURL = mediumImage.URL || null;
                          const offerPrice = item.Offers
                            ? item.Offers.Listings[0].Price.DisplayAmount
                            : null;
                          const title = item.ItemInfo.Title.DisplayValue;

                          return (
                            <React.Fragment>
                              <div style={productBox}>
                                <a target="_blank" href={item.DetailPageURL}>
                                  <img
                                    src={mediumImageURL}
                                    width={mediumImage.Width}
                                    height={mediumImage.Height}
                                    style={{
                                      marginTop:
                                        mediumImage.Height < 160
                                          ? 160 - mediumImage.Height
                                          : 0,
                                    }}
                                  />
                                </a>
                                <div style={productTitle}>{title}</div>
                                {/* <div style={deleteIconContainer}>
                                <span
                                  style={{
                                    display: "block",
                                    height: "40px",
                                    width: "40px",
                                    lineHeight: "40px",
                                    textAlign: "center",
                                    fontSize: "1.3em",
                                    position: "absolute",
                                    // marginRight: -10,
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    color={"#9E9E9E"}
                                  />
                                </span>
                              </div> */}
                                <div style={productPrice}>
                                  <p style={productPrice}>
                                    {offerPrice
                                      ? offerPrice.slice(0, 6)
                                      : 'N/A'}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </SlideToggle>
          {this.renderBottom(collection)}
        </div>
      </>
    );
  }

  createAccount() {
    const userId = localStorage.getItem('userId');
    const collection = this.state.collectionData;
    console.log(collection);
    axios
      .post(`${REACT_APP_API_URL}/api/auth/stripe/create`, {
        userId,
        collectionId: collection.collectionId,
      })
      .then(response => {
        const stripeOnboardingLink = response.data.accountLinks.url;
        window.location = stripeOnboardingLink;
      })
      .catch(error => {
        console.log(error);
      });
  }

  renderStripeProcess() {
    const styles = {
      circle: {
        borderRadius: '50%',
        width: '80px',
        height: '80px',
        padding: '20px',
        paddingTop: '23px',
        background: '#D3C3F7',
        border: '4px solid #9B7DD2',
        color: '#666',
        textAlign: 'center',
        font: '24px Arial, sans-serif',
        display: 'inline-block',
        marginLeft: 10,
        marginRight: 10,
      },
      header: {
        fontSize: '18px',
        color: '#666666',
        textAlign: 'center',
        marginTop: 10,
      },
      arrowContainer: {
        marginBottom: 50,
      },
    };
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={styles.stepContainer}>
          <div style={styles.circle}>
            <FontAwesomeIcon icon={faPiggyBank} color={'#9B7DD2'} size="30px" />
          </div>
          <div style={styles.header}>
            Connect
            <br />
            Stripe
          </div>
        </div>
        <div style={styles.arrowContainer}>
          <FontAwesomeIcon
            icon={faArrowAltRight}
            color={'#3DACFE'}
            size="50px"
          />
        </div>{' '}
        <div style={styles.stepContainer}>
          <div style={styles.circle}>
            <FontAwesomeIcon icon={faGiftCard} color={'#9B7DD2'} size="30px" />
          </div>
          <div style={styles.header}>
            Friends
            <br />
            Pledge
          </div>
        </div>
        <div style={styles.arrowContainer}>
          <FontAwesomeIcon
            icon={faArrowAltRight}
            color={'#3DACFE'}
            size="50px"
          />
        </div>
        <div style={styles.stepContainer}>
          <div style={styles.circle}>
            <FontAwesomeIcon icon={faGifts} color={'#9B7DD2'} size="30px" />
          </div>
          <div style={styles.header}>
            Receive
            <br />
            Funds
          </div>
        </div>
      </div>
    );
  }

  renderStripeConnect() {
    const { loading } = this.state;
    const firstName = localStorage.getItem('firstName');
    return (
      <>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#D3C3F7',
            width: '100%',
            zIndex: 1,
            top: 0,
            height: '60px',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title">
                  <h2
                    style={{
                      color: '#6D657F',
                      textTransform: null,
                      marginLeft: 20,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPiggyBank}
                      color={'#6D657F'}
                      size="30px"
                    />
                    <span style={{ marginLeft: 20 }}>Connect Bank Account</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p style={{ fontSize: 18, lineHeight: 1.5, margin: 20 }}>
            Hey, {firstName}! You have still to connect your bank details.
          </p>
          <p style={{ fontSize: 18, lineHeight: 1.5, margin: 20 }}>
            To continue to share with friends, connect your account with Stripe
            to receive collection funds.
          </p>
          {this.renderStripeProcess()}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 40,
            }}
          >
            {!loading ? (
              <>
                <button
                  onClick={() => this.setState({ showStripeConnect: false })}
                  className="btn btn-solid"
                  style={{
                    height: 35,
                    background: '#929292',
                    borderColor: '#929292',
                    width: '170px',
                    display: 'flex',
                    marginRight: 20,
                    borderRadius: 5,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 14,
                    textTransform: 'lowercase',
                  }}
                >
                  <span style={{ textTransform: 'uppercase' }}>C</span>ancel
                </button>
                <a
                  href="#"
                  onClick={() =>
                    this.setState({ loading: true }, () => this.createAccount())
                  }
                >
                  <img
                    className="center"
                    src={`${process.env.PUBLIC_URL}/assets/images/connect-button.png`}
                  />
                </a>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MoonLoader size={30} color={'#9B7DD2'} loading={loading} />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  renderRightSection() {
    const { showStripeConnect } = this.state;
    return (
      <div
        className="col-lg-8"
        style={{
          padding: 0,
        }}
      >
        <div className="dashboard-right">
          <div
            className="col-sm-12 blog-detail"
            style={{
              padding: 20,
            }}
          >
            {showStripeConnect && this.renderStripeConnect()}
            {!showStripeConnect && this.renderCollectionDetails()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const collection = this.state.collectionData;
    return (
      <div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#9B7DD2',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff' }}>{collection.collectionName}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          className="blog-detail-page section-b-space"
          style={{
            paddingTop: 0,
          }}
        >
          <div className="container">
            <div className="row">
              <Process step={2} />
              {this.renderRightSection()}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
  };
}

export default withRouter(connect(mapStateToProps, {})(Collection));

import React, {Component, Children} from 'react';
import { withTranslate } from 'react-redux-multilingual'
import { ToastContainer } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';

import HeaderOne from './common/headers/header-one';
import FooterOne from "./common/footers/footer-one";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { StripeProvider } from "react-stripe-elements";

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#fff'
      },
    secondary: {
        main: '#fff'
      }
    }
  },
)

const App = ({children }) => {
        return (
          <StripeProvider apiKey="pk_live_hWGxIJisVArjfuNkgyjLfKxD">
            <MuiThemeProvider muiTheme={theme}>
              <div>
                <HeaderOne />
                {children}
                <FooterOne />
                <ToastContainer />
              </div>
            </MuiThemeProvider>
          </StripeProvider>
        );
}

export default withStyles({ withTheme: true })(withTranslate(App));

import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import cookie from 'react-cookie';
import { Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../stripe/CheckoutForm';

class MakePayment extends Component {

    constructor (props) {
        super (props)

        this.state = {
          collectionData: [],
          buttonName: '',
          collectionUserId: ''
        }
    }





    render (){

        return (
            <div>
                <div className="breadcrumb-section" style={{backgroundColor: '#aa95d2'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="page-title">
                                    <h2 style={{color: '#fff'}}>Payment</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*Blog Details section*/}
                <section className="blog-detail-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 blog-detail">
                            <StripeProvider apiKey="pk_live_hWGxIJisVArjfuNkgyjLfKxD">
                            <div>
                            <Elements>
          <CheckoutForm />
        </Elements>
        </div>

</StripeProvider>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user
  };
}

export default withRouter(connect(mapStateToProps, { })(MakePayment));

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProcessSingular extends Component {
  render() {
    const { step, topSpacing, noTopPadding, leftSpacing } = this.props;
    const styles = {
      numberCircleComplete: {
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        padding: '10px',
        background: '#D3C3F7',
        border: '2px solid #9B7DD2',
        color: '#666',
        textAlign: 'center',
        font: '24px Arial, sans-serif',
        display: 'inline-block',
        marginLeft: 10,
        marginRight: 20,
      },
      numberCircleIncomplete: {
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        padding: '10px',
        background: '#E5DDF7',
        border: '2px solid #B1B1B1',
        color: '#B1B1B1',
        textAlign: 'center',
        font: '24px Arial, sans-serif',
        display: 'inline-block',
        marginLeft: 10,
        marginRight: 20,
      },
      headerComplete: {
        display: 'inline',
        fontSize: '22px',
        color: '#666666',
      },
      headerIncomplete: {
        display: 'inline',
        fontSize: '22px',
        color: '#B1B1B1',
      },
    };
    return (
      <div
        className="col-lg-4"
        style={{
          paddingTop: topSpacing ? 0 : 0,
          minHeight: 0,
          backgroundColor: '#462D77',
        }}
      >
        <div
          className="dashboard-left"
          style={{
            paddingTop: noTopPadding ? 0 : '20px',
            paddingBottom: '30px',
            paddingLeft: leftSpacing ? '20px' : 0,
          }}
        >
          <div
            className="block-content"
            style={{
              backgroundColor: '#EAE3F6',
              borderRadius: '10px',
            }}
          >
            <Link to={`${process.env.PUBLIC_URL}/create`}>
              <div
                style={
                  step === 1 || step === 0
                    ? styles.numberCircleComplete
                    : styles.numberCircleIncomplete
                }
              >
                1
              </div>
              <h4
                style={
                  step === 1 || step === 0
                    ? styles.headerComplete
                    : styles.headerIncomplete
                }
              >
                Create
              </h4>
              <p style={{ padding: 15, paddingTop: 10, paddingBottom: 0 }}>
                Create a new collection, set fund goal and choose a gift.
              </p>
            </Link>
          </div>
          <div
            className="block-content"
            style={{
              borderRadius: '10px',
              backgroundColor: '#EAE3F6',
              marginTop: 20,
            }}
          >
            <div
              style={
                step === 2 || step === 0
                  ? styles.numberCircleComplete
                  : styles.numberCircleIncomplete
              }
            >
              2
            </div>
            <h4
              style={
                step === 2 || step === 0
                  ? styles.headerComplete
                  : styles.headerIncomplete
              }
            >
              Share
            </h4>

            <p style={{ padding: 15, paddingTop: 10, paddingBottom: 0 }}>
              Invite friends to pledge funds towards the collection.
            </p>
          </div>
          <div
            className="block-content"
            style={{
              borderRadius: '10px',
              backgroundColor: '#EAE3F6',
              marginTop: 20,
            }}
          >
            <div
              style={
                step === 3 || step === 0
                  ? styles.numberCircleComplete
                  : styles.numberCircleIncomplete
              }
            >
              3
            </div>
            <h4
              style={
                step === 3 || step === 0
                  ? styles.headerComplete
                  : styles.headerIncomplete
              }
            >
              Buy
            </h4>
            <p style={{ padding: 15, paddingTop: 10, paddingBottom: 0 }}>
              When fund goal has been reached, funds are transferred from all
              pledgers to you.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ProcessSingular;

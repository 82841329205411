import React, { Component } from 'react';

import axios from 'axios';
import cookie from 'react-cookie';
import 'react-toastify/dist/ReactToastify.min.css';
import { Link, withRouter } from 'react-router-dom';
import DetailsWithPrice from '../products/common/product/details-price';
import DetailsTopTabs from '../products/common/details-top-tabs';
import Process from '../../components/common/process';

let offset = '217px';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

window.onscroll = function() {
  if (window.pageYOffset < 160) {
    offset = 217 - window.pageYOffset;
  } else {
    offset = 0;
  }
};
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: '',
      totalResults: 0,
      collectionData: [],
      itemData: [],
      searched: false,
      filterSelectOpen: false,
      category: 'All',
      itemPage: 1,
      offset: 0,
      products: [],
      initialLoad: true,
    };
    this.myRef = React.createRef();
  }

  componentWillMount() {
    this.getCollections();
  }

  getCart(cartId) {
    axios
      .get(`${REACT_APP_API_URL}/api/auth/cart/single/${cartId}`, {
        headers: { Authorization: cookie.load('token') },
      })
      .then(response => {
        this.setState({
          cartData: response.data,
        });
        this.getProducts();
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleScroll(event) {
    let scrollTop = event.srcElement.body.scrollTop,
      itemTranslate = Math.min(0, scrollTop / 3 - 60);

    this.setState({
      transform: itemTranslate,
    });
  }

  getCollections() {
    const { match = {} } = this.props;
    const id = match.params.id;
    axios
      .get(`${REACT_APP_API_URL}/api/auth/collection/single/${id}`, {
        headers: { Authorization: cookie.load('token') },
      })
      .then(response => {
        this.setState({
          collectionData: response.data,
          collectionUserId: response.data.userId,
        });
        this.searchItems(response.data);
        this.getCart(response.data.cartId);
      })
      .catch(error => {
        console.log(error);
      });
  }

  searchItems(collection) {
    let {
      keywords,
      maxSetPrice,
      minSetPrice,
      collectionData,
      itemPage,
    } = this.state;
    const minPrice = minSetPrice || 0;
    const maxPrice = maxSetPrice || collectionData.value;
    if (collection) {
      keywords = `${collection.collectionType} gifts`;
    }
    axios
      .post(`${REACT_APP_API_URL}/api/searchProducts`, {
        keywords,
        maxPrice: maxPrice * 100,
        minPrice: minPrice * 100,
        itemPage,
      })
      .then(response => {
        const itemData = response.data.SearchResult.Items;
        const totalResults = response.data.SearchResult.TotalResultCount;
        this.setState({ itemData, totalResults });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleAddToCollection() {
    const { match = {} } = this.props;
    const { collectionData } = this.state;
    const asin = match.params.asin;
    axios
      .put(`${REACT_APP_API_URL}/api/auth/cart/edit/${collectionData.cartId}`, {
        itemASIN: asin,
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getProducts() {
    const { match = {} } = this.props;
    const asin = match.params.asin;
    const { initialLoad } = this.state;
    if (initialLoad) {
      axios
        .post(`${REACT_APP_API_URL}/api/getProducts/`, {
          headers: { Authorization: cookie.load('token') },
          items: [asin],
        })
        .then(response => {
          console.log(response);
          this.setState({
            initialLoad: false,
            products: response.data.ItemsResult.Items,
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      return null;
    }
  }

  onChangePriceFilter(minSetPrice, maxSetPrice) {
    this.setState({ minSetPrice, maxSetPrice });
  }

  handlePageChange(offset, itemPage) {
    const { collectionData } = this.state;
    window.scrollTo(0, 0);
    this.setState({ itemPage, offset }, () => this.searchItems(collectionData));
  }

  render() {
    const collection = this.state.collectionData;
    const { match = {} } = this.props;
    const { products, cartData, itemAdded } = this.state;
    const item = products[0];
    const { asin } = match.params;
    const mediumImage = item && item.Images.Primary.Medium;
    const mediumImageURL = (item && mediumImage.URL) || null;
    const offerPrice =
      item && item.Offers ? item.Offers.Listings[0].Price.DisplayAmount : null;
    const externalURL = item && item.DetailPageURL;
    const title = item && item.ItemInfo.Title.DisplayValue;
    return (
      <div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#AB95D2',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff' }}>Gift Finder</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Section Start*/}
        {item ? (
          <section
            style={{
              paddingTop: '20px',
            }}
          >
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  <Process step={3} topSpacing />

                  {/* <span onClick={this.backClick} className="filter-back">
                        <i className="fa fa-angle-left" aria-hidden="true"></i>{" "}
                        back
                      </span> */}
                  <div className="col-lg-8 col-sm-12 col-xs-12">
                    <div className="">
                      <div
                        className="row"
                        style={{
                          paddingTop: '15px',
                          paddingBottom: '15px',
                        }}
                      >
                        <div>
                          <div>
                            <Link
                              to={`${process.env.PUBLIC_URL}/pages/search/${collection.collectionId}`}
                            >
                              <span
                                className="filter-btn"
                                style={{
                                  color: '#6E47BA',
                                  paddingLeft: '15px',
                                }}
                              >
                                <i
                                  className="fa fa-angle-left"
                                  aria-hidden="true"
                                ></i>{' '}
                                back to search
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 product-thumbnail">
                          <span
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                            }}
                          >
                            <img
                              src={mediumImageURL}
                              width={mediumImage.Width}
                              height={mediumImage.Height}
                              style={{
                                marginTop:
                                  mediumImage.Height < 160
                                    ? 160 - mediumImage.Height
                                    : 0,
                              }}
                            />
                          </span>
                        </div>
                        <DetailsWithPrice
                          externalURL={externalURL}
                          item={item}
                          cartData={cartData}
                          price={offerPrice}
                          title={title}
                          navOne={this.state.nav1}
                          handleAddToCollection={() =>
                            this.handleAddToCollection()
                          }
                          asin={asin}
                        />
                      </div>
                      <div className="product-buttons">
                        <div
                          style={{
                            flex: 10,
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: 40,
                            marginBottom: 40,
                          }}
                        >
                          <div style={{ flex: 4.5 }}>
                            <button
                              onClick={() =>
                                cartData && !cartData.items.includes(asin)
                                  ? this.setState({ itemAdded: true }, () =>
                                      this.handleAddToCollection(asin),
                                    )
                                  : console.log('already added')
                              }
                              className="btn btn-solid"
                              style={{
                                height: 60,
                                background: !itemAdded ? '#3DACFE' : '#6E47BA',
                                borderColor: !itemAdded ? '#3DACFE' : '#6E47BA',
                                width: '100%',
                                display: 'block',
                                color: 'white !important',
                              }}
                            >
                              {!itemAdded ? 'Add To Collection' : 'Added'}
                            </button>
                          </div>
                          <div style={{ flex: 0.4 }} />
                          <div style={{ flex: 4.5 }}>
                            <a href={externalURL} style={{ margin: 0 }}>
                              <button
                                className="btn btn-solid"
                                style={{
                                  height: 60,
                                  background: '#F59932',
                                  borderColor: '#F59932',
                                  width: '100%',
                                  display: 'block',
                                }}
                              >
                                View On Amazon
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DetailsTopTabs item={item} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ''
        )}
        {/*Section End*/}
      </div>
    );
  }
}

export default withRouter(Product);

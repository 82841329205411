import React, { Component } from 'react';

import axios from 'axios';
import cookie from 'react-cookie';
import MoonLoader from 'react-spinners/MoonLoader';
import Filter from '../collection/common/filter';
import SortDropdown from '../collection/common/sort-dropdown';
import NavigateNextIcon from '../common/NavigateNextIcon';
import NavigateBeforeIcon from '../common/NavigateNextIcon';
import XMLParser from 'react-xml-parser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Link, withRouter } from 'react-router-dom';
import Pagination from 'material-ui-flat-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { css } from '@emotion/core';
import ProcessSingular from '../../components/common/process-singular';
import DropdownButton from '../collection/common/dropdown-button';

import { faShoppingCart, faGift } from '@fortawesome/pro-solid-svg-icons';

const theme = createMuiTheme();

const productBox = {
  borderBottom: '3px solid #2DA6CF',
  border: '1px solid #D0D0D0',
  float: 'left',
  marginRight: '10px',
  marginLeft: '10px',
  marginBottom: '10px',
  position: 'relative',
  width: '170px',
  padding: '4px',
  paddingBottom: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const productTitle = {
  margin: '2px 3px 0 2px',
  minWidth: '40px',
  fontSize: '12px',
  lineHeight: '15px',
  color: '#000',
  position: 'relative',
  textAlign: 'center',
  display: 'block',
  overflow: 'hidden',
  height: '45px',
  fontWeight: 400,
  marginTop: '10px',
};

const productPrice = {
  textAlign: 'center',
  color: '#6E47BA',
  fontWeight: 'bold',
  paddingTop: '10px',
  fontSize: 18,
};

const { REACT_APP_API_URL } = process.env;
let offset = '205px';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: '',
      totalResults: 0,
      collectionData: [],
      itemData: [],
      searched: false,
      filterSelectOpen: false,
      category: 'All',
      itemPage: 1,
      pageOffset: 0,
      offset: '205px',
      amazonFailure: false,
      loading: true,
      showSort: false,
      step: 3,
      headerHeight: 0,
      sortBy: 'Relevance',
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const step = query.get('step');
    if (step) {
      this.setState({ step: parseInt(step) });
    }
    const headerHeight = document.getElementById('sticky').clientHeight;
    this.setState({ headerHeight, offset: 0 });
    this.getCollections();
  }

  getCart(cartId) {
    axios
      .get(`${REACT_APP_API_URL}/api/auth/cart/single/${cartId}`, {
        headers: { Authorization: cookie.load('token') },
      })
      .then(response => {
        console.log(response);
        this.setState({
          cartData: response.data,
          amazonFailure: false,
        });
        console.log(response);
      })
      .catch(error => {
        this.setState({ loading: false, amazonFailure: true });

        console.log(error);
      });
  }

  handleScroll(event) {
    const { headerHeight } = this.state;
    console.log(headerHeight);
    if (window.pageYOffset < 160) {
      offset = headerHeight + 120 - window.pageYOffset;
    } else {
      offset = 0;
    }
    this.setState({ offset });
  }

  async getCollections() {
    const { match = {} } = this.props;
    const id = match.params.id;
    await axios
      .get(`${REACT_APP_API_URL}/api/auth/collection/single/${id}`, {
        headers: { Authorization: cookie.load('token') },
      })
      .then(async response => {
        this.setState({
          collectionData: response.data,
          collectionUserId: response.data.userId,
        });
        this.searchItems(response.data);
        this.getCart(response.data.cartId);
      })
      .catch(error => {
        this.setState({
          loading: false,
          amazonFailure: true,
        });
        console.log(error);
      });
  }

  async searchItems(collection) {
    this.setState({ loading: true }, () => {
      let {
        keywords,
        maxSetPrice,
        minSetPrice,
        collectionData,
        itemPage,
        sortBy,
      } = this.state;
      const minPrice = minSetPrice || 0;
      const maxPrice = maxSetPrice || collectionData.value;
      if (collection) {
        keywords = `${collection.collectionType} gifts`;
      }
      axios
        .post(`${REACT_APP_API_URL}/api/searchProducts`, {
          keywords,
          maxPrice: maxPrice * 100,
          minPrice: minPrice * 100,
          itemPage,
          sortBy,
        })
        .then(response => {
          const itemData = response.data.SearchResult.Items;
          const totalResults = response.data.SearchResult.TotalResultCount;
          this.setState({
            itemData,
            totalResults,
            loading: false,
            amazonFailure: false,
          });
        })
        .catch(error => {
          this.setState({ loading: false, amazonFailure: true });
          console.log(error);
        });
    });
  }

  addToCart(url) {
    axios
      .post(url)
      .then(response => {
        const data = response.data;
        const xml = new XMLParser().parseFromString(data); // Assume xmlText contains the example XML

        const cartResponse = xml.children.find(x => x.name === 'Cart');
        const cartId = cartResponse.children.find(x => x.name === 'CartId')
          .value
          ? cartResponse.children.find(x => x.name === 'CartId').value
          : null;
        const hmac = cartResponse.children.find(x => x.name === 'HMAC').value
          ? cartResponse.children.find(x => x.name === 'HMAC').value
          : null;
        this.handleCart(cartId, hmac);
      })
      .catch(error => {
        console.log(error);
      });
  }

  appendCart(url) {
    axios
      .post(url)
      .then(response => {
        const data = response.data;
        const xml = new XMLParser().parseFromString(data); // Assume xmlText contains the example XML

        const cartResponse = xml.children.find(x => x.name === 'Cart');
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleCart(cartId, hmac) {
    const { match = {} } = this.props;
    const collectionId = match.params.id;
    const collectionData = this.state.collectionData;
    const collectionName = collectionData.collectionName;
    const collectionType = collectionData.collectionType;
    const noOfPeople = collectionData.noOfPeople;
    const usersFunded = collectionData.usersFunded;
    const userId = collectionData.userId;
    const value = collectionData.value;

    axios
      .put(`${REACT_APP_API_URL}/api/auth/collection/edit/${collectionId}`, {
        collectionName,
        collectionType,
        noOfPeople,
        userId,
        value,
        usersFunded,
        cartId,
        hmac,
      })
      .then(response => {
        toast.success('Item Added to Collection');
      })
      .catch(error => {
        console.log(error);
      });
  }

  seperateKeywords() {
    const { keywords, collectionData } = this.state;

    let newKeywords = keywords.replace(' ', '%20');
    if (!this.state.searched) {
      newKeywords = collectionData
        ? collectionData.collectionType + '%20gift'
        : '%20';
    }

    return newKeywords;
  }

  handleChange(event) {
    this.setState({ keywords: event.target.value });
  }

  handleSubmit(event) {
    this.setState({ searched: true });
    event.preventDefault();
    this.searchItems();
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  handleAddToCollection(asin, i) {
    const collectionData = this.state.collectionData;
    const { itemData } = this.state;
    itemData[i].added = true;
    console.log(asin);
    console.log(itemData);
    this.setState({ itemData });
    axios
      .put(`${REACT_APP_API_URL}/api/auth/cart/edit/${collectionData.cartId}`, {
        itemASIN: asin,
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  onChangePriceFilter(minSetPrice, maxSetPrice) {
    this.setState({ minSetPrice, maxSetPrice });
  }

  handlePageChange(pageOffset, itemPage) {
    const { collectionData } = this.state;
    window.scrollTo(0, 0);
    this.setState({ itemPage, pageOffset }, () =>
      this.searchItems(collectionData),
    );
  }

  onSortBy(e) {
    this.setState({ sortBy: e, showSort: false }, () => {
      this.searchItems();
    });
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    const collection = this.state.collectionData;
    const { sortBy, keywords } = this.state;
    const {
      minSetPrice,
      maxSetPrice,
      cartData,
      loading,
      itemData,
      step,
      amazonFailure,
    } = this.state;
    return (
      <div style={{ backgroundColor: 'white' }}>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#AB95D2',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff' }}>{'Gift Finder'}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#6E47BA',
            zIndex: 1,
            height: 60,
            alignItems: 'center',
            display: 'flex',
            // width: "100%",
          }}
        >
          <div
            className="row"
            style={{
              width: '100%',
            }}
          >
            <div
              className="col-lg-4"
              style={{
                minHeight: 0,
              }}
            >
              <div className="dashboard-left" style={{}}></div>
            </div>
            <div
              className="col-lg-8"
              style={{ paddingRight: 0, paddingLeft: '0px !important' }}
            >
              <div className="dashboard-right" style={{ marginLeft: 10 }}>
                <div style={{ width: '100%', display: 'inline' }}>
                  <form
                    className="form-header"
                    onSubmit={event => this.handleSubmit(event)}
                  >
                    <div
                      className="input-group"
                      style={{
                        height: '40px',
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder={`Search Gifts... e.g. ${collection.collectionType}`}
                        value={this.state.keywords}
                        onChange={event => this.handleChange(event)}
                        style={{
                          height: '40px',
                        }}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-solid"
                          type="submit"
                          style={{
                            backgroundColor: 'white',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Search section*/}
        <div>
          <div
            className="row"
            style={{ backgroundColor: 'white', marginRight: 0 }}
          >
            <ProcessSingular step={step} topSpacing leftSpacing />
            <div
              className="col-lg-8"
              style={{ paddingRight: 0, paddingLeft: 0 }}
            >
              <div className="dashboard-right">
                <div className="authentication-page">
                  <div style={{ backgroundColor: 'white' }}>
                    {
                      <div
                        className="breadcrumb-section"
                        style={{
                          backgroundColor: '#EAE3F6',
                          position: 'sticky',
                          width: '100%',
                          zIndex: 1,
                          top: 0,
                          marginTop: this.state.offset,
                          height: '60px',
                          padding: 0,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          className="container"
                          style={{
                            marginLeft: 0,
                          }}
                        >
                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {this.state.searched ? (
                                <>
                                  <span style={{ marginLeft: '10px' }}>
                                    <DropdownButton
                                      label="PRICE"
                                      onPress={() =>
                                        this.setState({
                                          filterSelectOpen: !this.state
                                            .filterSelectOpen,
                                          showSort: false,
                                        })
                                      }
                                    />
                                  </span>
                                  <DropdownButton
                                    label="SORT BY"
                                    onPress={() =>
                                      this.setState({
                                        showSort: !this.state.showSort,
                                        filterSelectOpen: false,
                                      })
                                    }
                                  />
                                </>
                              ) : (
                                <span
                                  style={{ fontSize: '16px', marginLeft: 20 }}
                                >
                                  Suggested Gifts
                                </span>
                              )}
                              <Link
                                to={`${process.env.PUBLIC_URL}/collection/${collection.collectionId}`}
                                style={{
                                  flex: 1,
                                }}
                              >
                                <button
                                  className="btn btn-solid"
                                  onClick={() => console.log('')}
                                  style={{
                                    background: '#3DACFF',
                                    borderColor: '#3DACFF',
                                    width: '120px',
                                    // height: "28px",
                                    float: 'right',
                                    // marginRight: 10,
                                    padding: '7px 15px',
                                  }}
                                >
                                  <div>
                                    <FontAwesomeIcon
                                      icon={faShoppingCart}
                                      color={'#fff'}
                                      size="30px"
                                    />
                                    <span
                                      style={{ color: 'white', marginLeft: 15 }}
                                    >
                                      DONE
                                    </span>
                                  </div>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.filterSelectOpen && (
                      <Filter
                        getData={() => {
                          console.log(this.props);
                          this.searchItems();
                          this.setState({ filterSelectOpen: false });
                        }}
                        collectionValue={collection.value}
                        minPrice={minSetPrice || 0}
                        maxPrice={maxSetPrice || collection.value}
                        closeFilter={() =>
                          this.setState({ filterSelectOpen: false })
                        }
                        onChangePriceFilter={value => {
                          this.setState({
                            minSetPrice: value.min,
                            maxSetPrice: value.max,
                          });
                        }}
                      />
                    )}
                    {this.state.showSort && (
                      <SortDropdown
                        sortBy={sortBy}
                        getData={() => this.searchItems()}
                        onSortBy={e => this.onSortBy(e)}
                        collectionValue={collection.value}
                        closeFilter={() => this.setState({ showSort: false })}
                      />
                    )}

                    {!amazonFailure && loading && (
                      <div
                        style={{
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: 500,
                          marginTop: 150,
                        }}
                      >
                        <MoonLoader
                          color={'#671FB0'}
                          css={override}
                          loading={loading}
                          size={50}
                        />
                      </div>
                    )}
                    {amazonFailure && !loading && (
                      <div
                        className="col-md-12"
                        style={{
                          alignItems: 'center',
                          marginTop: 20,
                          justifyContent: 'center',
                          padding: 0,
                        }}
                      >
                        <h3
                          style={{
                            textAlign: 'center',
                            paddingTop: 70,
                          }}
                        >
                          {!keywords.length
                            ? 'Enter keywords to find gifts'
                            : ' Amazon Service Unavailable'}
                        </h3>
                        <h3
                          style={{
                            textAlign: 'center',
                            paddingTop: 10,
                          }}
                        >
                          {!keywords.length ? '' : 'Please Try Again Later'}
                        </h3>
                        <div
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            display: 'flex',
                            paddingTop: 10,
                          }}
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            style={{
                              fontSize: '20em',
                              color: '#d3c3f7',
                              textAlign: 'center',
                            }}
                          ></i>
                        </div>
                      </div>
                    )}
                    {!amazonFailure && !loading && (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '20px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          paddingLeft: '30px',
                          // paddingRight: "15px",
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            height: 'auto',
                            width: '100%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            backgroundColor: 'white',
                            // marginTop: 20,
                          }}
                        >
                          {itemData.map((item, i) => {
                            const asin = item.ASIN;
                            const mediumImage = item.Images.Primary.Medium;
                            const mediumImageURL = mediumImage.URL || null;
                            const offerPrice = item.Offers
                              ? item.Offers.Listings[0].Price.DisplayAmount
                              : null;
                            const title = item.ItemInfo.Title.DisplayValue;
                            const reviewStars = item.CustomerReviews;
                            return (
                              <React.Fragment>
                                <div style={productBox}>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/pages/product/${collection.collectionId}/${asin}`}
                                  >
                                    <img
                                      src={mediumImageURL}
                                      width={mediumImage.Width}
                                      height={mediumImage.Height}
                                      style={{
                                        marginTop:
                                          mediumImage.Height < 160
                                            ? 160 - mediumImage.Height
                                            : 0,
                                      }}
                                    />
                                  </Link>
                                  <div style={productTitle}>{title}</div>
                                  <p style={productPrice}>
                                    {offerPrice
                                      ? offerPrice.slice(0, 6)
                                      : 'N/A'}
                                  </p>
                                  <div>
                                    <button
                                      className="btn btn-solid"
                                      onClick={() =>
                                        cartData &&
                                        !cartData.items.includes(asin)
                                          ? this.handleAddToCollection(asin, i)
                                          : console.log('already added')
                                      }
                                      style={{
                                        background:
                                          item.added ||
                                          (cartData &&
                                            cartData.items.includes(asin))
                                            ? '#6E47BA'
                                            : '#3DACFF',
                                        borderColor:
                                          item.added ||
                                          (cartData &&
                                            cartData.items.includes(asin))
                                            ? '#6E47BA'
                                            : '#3DACFF',
                                        width: '120px',
                                      }}
                                    >
                                      {item.added ||
                                      (cartData &&
                                        cartData.items.includes(asin)) ? (
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faGift}
                                            color={'#fff'}
                                            size="30px"
                                          />
                                          <span
                                            style={{
                                              color: 'white',
                                              marginLeft: 15,
                                            }}
                                          >
                                            ADDED
                                          </span>
                                        </div>
                                      ) : (
                                        'Add'
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {this.state.totalResults > 0 && (
                      <div
                        style={{
                          marginLeft: 15,
                          paddingTop: 15,
                          paddingBottom: 15,
                        }}
                      >
                        <MuiThemeProvider theme={theme}>
                          <CssBaseline />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {keywords.length && (
                              <Pagination
                                size="large"
                                limit={this.state.totalResults / 10}
                                offset={this.state.pageOffset}
                                total={100}
                                previousPageLabel={
                                  <NavigateBeforeIcon
                                    type="left"
                                    noOfPages={this.state.totalResults / 10}
                                    currentPage={this.state.itemPage}
                                  />
                                }
                                nextPageLabel={
                                  <NavigateNextIcon
                                    type="right"
                                    noOfPages={this.state.totalResults / 10}
                                    currentPage={this.state.itemPage}
                                  />
                                }
                                onClick={(e, pageOffset, page) =>
                                  this.handlePageChange(pageOffset, page)
                                }
                              />
                            )}
                          </div>
                        </MuiThemeProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Search);

import React, { Component } from 'react';
import './dropdown-style.css';
import Card from '@material-ui/core/Card';
import { Link, withRouter } from 'react-router-dom';
import { createCollection } from '../../actions/index';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import axios from 'axios';
import './index.less';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Process from '../../components/common/process';
import {
  faRingsWedding,
  faBirthdayCake,
  faGlassCheers,
  faHeartCircle,
  faTruckMoving,
  faBaby,
  faHandshake,
  faGraduationCap,
  faHatSanta,
  faGift,
} from '@fortawesome/pro-solid-svg-icons';

const uuidv4 = require('uuid/v4');

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const ResponsiveGridLayout = WidthProvider(Responsive);
const form = reduxForm({
  form: 'collection',
});
class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionType: 'Birthday',
      included: true,
      step: 1,
      collection: [],
    };
  }

  handleFormSubmit(formProps) {
    const { email, userId } = this.props;
    formProps.collectionId = uuidv4();
    formProps.cartId = uuidv4();
    formProps.userId = userId;
    formProps.firstName = localStorage.getItem('firstName');
    formProps.lastName = localStorage.getItem('lastName');
    formProps.stripeId = localStorage.getItem('stripeId');
    formProps.mainEmail = email;
    formProps.collectionType = this.state.collectionType;
    formProps.usersFunded = 1;
    formProps.amountPledged = 0;
    axios
      .post(`${REACT_APP_API_URL}/api/auth/cart`, {
        cartId: formProps.cartId,
        collectionId: formProps.collectionId,
      })
      .then(response => {
        window.scrollTo(0, 0);
      })
      .catch(error => {
        console.log(error);
      });
    this.props.createCollection(formProps, this.props.history);
    this.props.reset();
    this.setState({ step: 3, collectionId: formProps.collectionId });
  }

  onSelect(props) {
    this.setState({ collectionType: props.value });
  }

  getAutoResponsiveProps() {
    return {
      itemMargin: 10,
      containerWidth: this.state.containerWidth || document.body.clientWidth,
      itemClassName: 'item',
      gridWidth: 100,
      transitionDuration: '.5',
    };
  }

  renderStep1() {
    const collectionTypes = [
      {
        name: 'Birthday',
        icon: faBirthdayCake,
      },
      {
        name: 'Engagement',
        icon: faGlassCheers,
      },
      {
        name: 'Wedding',
        icon: faRingsWedding,
      },
      {
        name: 'Anniversary',
        icon: faHeartCircle,
      },
      {
        name: 'New Home',
        icon: faTruckMoving,
      },
      {
        name: 'Baby Shower',
        icon: faBaby,
      },
      {
        name: 'Graduation',
        icon: faGraduationCap,
      },
      {
        name: 'Leaving',
        icon: faHandshake,
      },
      {
        name: 'Secret Santa',
        icon: faHatSanta,
      },
      {
        name: 'Other',
        icon: faGift,
      },
    ];
    const largeLayout = [
      { i: '0', x: 0, y: 0, w: 2, h: 1 },
      { i: '1', x: 2, y: 0, w: 2, h: 1 },
      { i: '2', x: 4, y: 0, w: 2, h: 1 },
      { i: '3', x: 6, y: 0, w: 2, h: 1 },
      { i: '4', x: 0, y: 0, w: 2, h: 1 },
      { i: '5', x: 2, y: 0, w: 2, h: 1 },
      { i: '6', x: 4, y: 1, w: 2, h: 1 },
      { i: '7', x: 6, y: 1, w: 2, h: 1 },
      { i: '8', x: 0, y: 1, w: 2, h: 1 },
      { i: '9', x: 2, y: 1, w: 2, h: 1 },
    ];

    const medLayout = [
      { i: '0', x: 0, y: 0, w: 2, h: 1 },
      { i: '1', x: 2, y: 0, w: 2, h: 1 },
      { i: '2', x: 4, y: 0, w: 2, h: 1 },
      { i: '3', x: 0, y: 0, w: 2, h: 1 },
      { i: '4', x: 2, y: 0, w: 2, h: 1 },
      { i: '5', x: 4, y: 0, w: 2, h: 1 },
      { i: '6', x: 0, y: 1, w: 2, h: 1 },
      { i: '7', x: 2, y: 1, w: 2, h: 1 },
      { i: '8', x: 4, y: 1, w: 2, h: 1 },
      { i: '9', x: 0, y: 1, w: 2, h: 1 },
    ];
    const smallLayout = [
      { i: '0', x: 0, y: 0, w: 2, h: 1 },
      { i: '1', x: 2, y: 0, w: 2, h: 1 },
      { i: '2', x: 0, y: 0, w: 2, h: 1 },
      { i: '3', x: 2, y: 1, w: 2, h: 1 },
      { i: '4', x: 0, y: 1, w: 2, h: 1 },
      { i: '5', x: 2, y: 1, w: 2, h: 1 },
      { i: '6', x: 0, y: 2, w: 2, h: 1 },
      { i: '7', x: 2, y: 2, w: 2, h: 1 },
      { i: '8', x: 0, y: 2, w: 2, h: 1 },
      { i: '9', x: 2, y: 3, w: 2, h: 1 },
    ];

    const xSmallLayout = [
      { i: '0', x: 0, y: 0, w: 2, h: 1 },
      { i: '1', x: 2, y: 0, w: 2, h: 1 },
      { i: '2', x: 0, y: 1, w: 2, h: 1 },
      { i: '3', x: 2, y: 1, w: 2, h: 1 },
      { i: '4', x: 0, y: 2, w: 2, h: 1 },
      { i: '5', x: 2, y: 2, w: 2, h: 1 },
      { i: '6', x: 0, y: 3, w: 2, h: 1 },
      { i: '7', x: 2, y: 3, w: 2, h: 1 },
      { i: '8', x: 0, y: 4, w: 2, h: 1 },
      { i: '9', x: 2, y: 4, w: 2, h: 1 },
    ];

    const xsSmallLayout = [
      { i: '0', x: 0, y: 0, w: 1, h: 1 },
      { i: '1', x: 1, y: 0, w: 1, h: 1 },
      { i: '2', x: 0, y: 1, w: 1, h: 1 },
      { i: '3', x: 1, y: 1, w: 1, h: 1 },
      { i: '4', x: 0, y: 2, w: 1, h: 1 },
      { i: '5', x: 1, y: 2, w: 1, h: 1 },
      { i: '6', x: 0, y: 3, w: 1, h: 1 },
      { i: '7', x: 1, y: 3, w: 1, h: 1 },
      { i: '8', x: 0, y: 4, w: 1, h: 1 },
      { i: '9', x: 1, y: 4, w: 1, h: 1 },
    ];

    const layouts = {
      lg: largeLayout,
      md: medLayout,
      sm: smallLayout,
      xs: xSmallLayout,
      xxs: xsSmallLayout,
    };
    return (
      <div className="row" style={{ backgroundColor: 'white', marginRight: 0 }}>
        {' '}
        <Process step={1} topSpacing leftSpacing />
        <div className="col-lg-8" style={{ paddingRight: 0 }}>
          <div className="dashboard-right">
            <div
              className="breadcrumb-section"
              style={{
                backgroundColor: '#D3C3F7',
                height: 60,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="page-title"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/number-1.png`}
                        style={{
                          width: '30px',
                          height: 'auto',
                          marginRight: 15,
                        }}
                        className="img-fluid"
                        alt=""
                        resizeMode="contain"
                      />
                      <div>
                        <h2
                          style={{
                            color: '#6D657F',
                            fontFamily: 'Quicksand',
                            textTransform: 'none',
                          }}
                        >
                          Select an occasion
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container"
              style={{ width: '100vw', marginTop: 20, marginBottom: 20 }}
            >
              <ResponsiveGridLayout
                isDraggable={false}
                isResizable={false}
                layouts={layouts}
                className="layout"
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
              >
                {collectionTypes.map((occasion, index) => (
                  <Card
                    onClick={() => {
                      window.scrollTo(0, 0);
                      this.setState({
                        step: 2,
                        icon: occasion.icon,
                        collectionType: occasion.name,
                      });
                    }}
                    key={index}
                    style={{
                      cursor: 'pointer',
                      flexDirection: 'column',
                      display: 'flex',
                      backgroundColor: '#3DACFE',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 200,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#3DACFE',
                      }}
                    >
                      <span
                        style={{
                          display: 'block',
                          height: '70px',
                          width: '70px',
                          lineHeight: '70px',
                          borderRadius: '35px',
                          borderWidth: 3,
                          backgroundColor: '#3DACFE',
                          borderColor: '#3DACFE',
                          textAlign: 'center',
                          fontSize: '2.6em',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={occasion.icon}
                          color={'#AFDDFD'}
                        />
                      </span>
                    </div>
                    <h4 style={{ fontFamily: 'Quicksand', color: '#fff' }}>
                      {occasion.name}
                    </h4>
                  </Card>
                ))}
              </ResponsiveGridLayout>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStep2() {
    const { handleSubmit } = this.props;
    const { collectionType } = this.state;
    return (
      <div>
        <div
          className="row"
          style={{ backgroundColor: 'white', marginRight: 0 }}
        >
          <Process step={1} topSpacing leftSpacing />
          <div className="col-lg-8" style={{ paddingRight: 0 }}>
            <div className="dashboard-right">
              <div
                className="breadcrumb-section"
                style={{
                  backgroundColor: '#D3C3F7',
                  height: 60,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="page-title"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/number-2.png`}
                          style={{
                            width: '30px',
                            height: 'auto',
                            marginRight: 15,
                          }}
                          className="img-fluid"
                          alt=""
                          resizeMode="contain"
                        />
                        <div>
                          <h2
                            style={{
                              color: '#6D657F',
                              fontFamily: 'Quicksand',
                              textTransform: 'none',
                            }}
                          >
                            Enter details
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <section
                    className=" contact-page section-b-space"
                    style={{
                      paddingTop: 30,
                    }}
                  >
                    <form
                      className="theme-form"
                      onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
                    >
                      <div className="form-row">
                        <div className="col-md-6">
                          <label htmlFor="name">
                            Collection Name{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            name="collectionName"
                            className="form-control"
                            component="input"
                            type="text"
                            placeholder={`e.g. Andy's ${collectionType}`}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="review">
                            Total Fund Goal (£){' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            name="value"
                            className="form-control"
                            type="number"
                            placeholder="Enter gift total"
                            component="input"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="review">
                            How much would you like each person to contribute?{' '}
                            <span style={{ color: '#B7B9BC' }}>(optional)</span>
                          </label>
                          <Field
                            name="suggestedAmount"
                            className="form-control"
                            component="input"
                            type="number"
                            placeholder="Enter amount"
                          />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="review">
                            Description{' '}
                            <span style={{ color: '#B7B9BC' }}>(optional)</span>
                          </label>
                          <Field
                            name="collectionDescription"
                            className="form-control"
                            component="input"
                            type="text"
                            placeholder="Enter Collection Description (Optional)"
                          />
                        </div>
                        <div
                          style={{
                            paddingBottom: 10,
                            paddingRight: 10,
                            width: '100%',
                          }}
                        >
                          <span
                            style={{
                              float: 'right',
                            }}
                          >
                            <span style={{ color: 'red' }}>*</span> required
                            fields
                          </span>
                        </div>
                        <div className="col-md-12">
                          <div
                            style={{
                              width: '100%',
                              marginTop: 10,
                            }}
                          >
                            <button
                              className="btn"
                              type="submit"
                              style={{
                                backgroundColor: '#3DACFF',
                                color: 'white',
                                height: 60,
                                background: '#3DACFE',
                                borderColor: '#3DACFE',
                                borderWidth: 0,
                                width: '100%',
                                display: 'block',
                              }}
                            >
                              Create Your Collection
                            </button>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              marginTop: 20,
                            }}
                          >
                            <button
                              className="btn"
                              onClick={() => {
                                this.setState({ step: 1 });
                                this.props.reset();
                              }}
                              style={{
                                backgroundColor: '#B7B9BC',
                                color: 'white',
                                height: 60,
                                background: '#B7B9BC',
                                borderColor: '#B7B9BC',
                                borderWidth: 0,
                                width: '100%',
                                display: 'block',
                              }}
                            >
                              Go Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStep3() {
    return (
      <div>
        <div
          className="row"
          style={{ backgroundColor: 'white', marginRight: 0 }}
        >
          <Process step={1} topSpacing leftSpacing />
          <div className="col-lg-8" style={{ paddingRight: 0 }}>
            <div className="dashboard-right">
              <div
                className="breadcrumb-section"
                style={{
                  backgroundColor: '#D3C3F7',
                  height: 60,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="page-title"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/number-3.png`}
                          style={{
                            width: '30px',
                            height: 'auto',
                            marginRight: 15,
                          }}
                          className="img-fluid"
                          alt=""
                          resizeMode="contain"
                        />
                        <div>
                          <h2
                            style={{
                              color: '#6D657F',
                              fontFamily: 'Quicksand',
                              textTransform: 'none',
                            }}
                          >
                            Find gifts
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  {this.renderStep3Content()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getCollection() {
    const id = this.state.id;
    axios
      .get(`${REACT_APP_API_URL}/api/auth/collection/single/${id}`, {
        headers: { Authorization: cookie.load('token') },
      })
      .then(response => {
        const stripeId = response.data.stripeId;
        this.setState({
          collection: response.data,
          collectionUserId: response.data.userId,
          stripeId,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  renderStep3Content() {
    const { collectionId } = this.state;
    return (
      <div>
        <div
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/tick.png`}
              style={{
                width: '180px',
                height: 'auto',
                marginTop: 20,
              }}
              className="img-fluid"
              alt=""
              resizeMode="contain"
            />
          </div>
          <br />
          <h3
            style={{ color: '#4F4F4F', fontSize: '1.5em', textAlign: 'center' }}
          >
            Collection Created!
          </h3>
          <br />
          <Link
            to={`${process.env.PUBLIC_URL}/pages/search/${collectionId}?step=1`}
          >
            <div
              style={{
                marginLeft: 30,
                marginRight: 30,
                flexDirection: 'row',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CardContent
                style={{
                  backgroundColor: '#9374CF',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingBottom: '16px',
                  width: '300px',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    display: 'inline',
                    justifyContent: 'center',
                  }}
                >
                  <i
                    className="fa fa-search"
                    style={{
                      fontSize: '1.5em',
                      color: '#d3c3f7',
                    }}
                  ></i>
                </div>

                <Typography
                  component="h6"
                  variant="h6"
                  style={{
                    color: '#fff',
                    textAlign: 'center',
                    marginLeft: 20,
                  }}
                >
                  Start Searching Gifts
                </Typography>
              </CardContent>
            </div>
          </Link>
          <br />
        </div>
      </div>
    );
  }
  render() {
    const { step } = this.state;
    return (
      <div>
        <div
          className="breadcrumb-section"
          style={{
            backgroundColor: '#9B7DD2',
            height: 60,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="page-title">
                  <h2 style={{ color: '#fff' }}>{'Create a Collection'}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStep2()}
          {step === 3 && this.renderStep3()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    userId: state.authentication.userId,
    email: state.authentication.email,
  };
}

export default withRouter(
  connect(mapStateToProps, { createCollection })(form(Contact)),
);

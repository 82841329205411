import React, { Component } from 'react';
import './dropdown-style.css';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { createCollection } from '../../actions/index';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

const form = reduxForm({
  form: 'collection',
});

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class Connect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionType: 'Birthday',
      loading: false,
    };
  }

  createAccount() {
    const userId = localStorage.getItem('userId');
    axios
      .post(`${REACT_APP_API_URL}/api/auth/stripe/create`, {
        userId,
      })
      .then(response => {
        const stripeOnboardingLink = response.data.accountLinks.url;
        window.location = stripeOnboardingLink;
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log(error);
      });
  }

  render() {
    const { loading } = this.state;

    return (
      <LoadingOverlay active={loading} spinner={<BounceLoader />}>
        <div>
          <div
            className="breadcrumb-section"
            style={{ backgroundColor: '#aa95d2' }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="page-title">
                    <h2 style={{ color: '#fff' }}>{'Create a Collection'}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className=" contact-page section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div style={{ height: '600px' }}>
                    <h3 style={{ fontSize: 30 }}>Connect with your bank</h3>
                    <br />

                    <p style={{ fontSize: 18, lineHeight: 1.5 }}>
                      To continue creating a collection, connect your account
                      with stripe to receive collection funds.
                    </p>
                    <br />
                    <div style={{ width: '100%' }}>
                      <a
                        href="#"
                        onClick={() =>
                          this.setState({ loading: true }, () =>
                            this.createAccount(),
                          )
                        }
                      >
                        <img
                          className="center"
                          src={`${process.env.PUBLIC_URL}/assets/images/connect-button.png`}
                        />
                      </a>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
  };
}

export default withRouter(
  connect(mapStateToProps, { createCollection })(form(Connect)),
);

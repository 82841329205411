import React from 'react';
import 'react-input-range/lib/css/index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
export default function DropdownButton({ onPress, label }) {
  return (
    <button
      className="btn btn-solid"
      onClick={() => onPress()}
      style={{
        background: '#CFB8F6',
        borderColor: '#6E47BA',
        width: '120px',
        marginRight: 10,
        padding: '7px 15px',
      }}
    >
      <div>
        <span style={{ color: '#3D3D3D' }}>{label}</span>
        <span style={{ float: 'right' }}>
          <FontAwesomeIcon icon={faChevronDown} color="#3D3D3D" size="10px" />
        </span>
      </div>
    </button>
  );
}
